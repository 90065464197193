import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "../SideBar.module.css";

import icon from "../imgs/icons_insta.png";

import { bringInstagramToFront } from "../../../redux/modules/content/instagram";

import { incrementTopZ } from "../../../redux/modules/ui";

/**
 * @author  Francis Goodship and Conan Lai
 * @description takes in height/width prop from Icons component, which calculates size based
 * on number of icons
 */

class InstaIcon extends React.PureComponent {
  render() {
    return (
      <div
        className={styles.element}
        style={{
          height: this.props.height ? `${this.props.height}%` : null,
          width: this.props.width ? `${this.props.width}%` : null,
        }}
        data-tip="instagram"
      >
        <div className={styles.imgwrapper}>
          <a target="blank" href="https://www.instagram.com/n10.as">
            <img src={icon} />
          </a>
        </div>
      </div>
    );
  }
}

InstaIcon.propTypes = {
  isMobile: PropTypes.bool,
  closeAll: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  bringInstagramToFront: PropTypes.func,
  incrementTopZ: PropTypes.func,
  topZIndex: PropTypes.number,
};

const mapPropsToState = (state) => {
  return {
    z: state._instagram.z,
    topZIndex: state._ui.topZIndex,
  };
};

const ConnectedInstaIcon = connect(mapPropsToState, {
  bringInstagramToFront,
  incrementTopZ,
})(InstaIcon);

export default ConnectedInstaIcon;
