import React from 'react';
import Background from './Background';
import config from '../../../../config';

class EditBackgrounds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgrounds: [],
    };
    this.updateData = this.updateData.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.updateData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateData() {
    fetch(`${config.api_path}/backgrounds`).then((response) => {
      return response.json();
    }).then((json) => {
      if (this.mounted === true) {
        this.setState({
          backgrounds: json,
        });
      }
    });
  }

  render() {
    return (
      <div>
        {this.state.backgrounds.map((bg, i) => {
          return (
            <Background
              {...bg}
              num={i + 1}
              key={bg._id}
              updateData={this.updateData}
            />
          );
        })}
      </div>
    );
  }
}

export default EditBackgrounds;
