import React, { useEffect } from 'react';
import config from '../../../../config';
import styles from '../AdminEditor.module.css';

export default function UserAssociateShow({ shows }) {
	const [loading, setLoading] = React.useState(true);
	const [users, setUsers] = React.useState([]);
	const [selectedShow, setSelectedShow] = React.useState('');
	const [selectedUser, setSelectedUser] = React.useState('');
	const [isSubmissionValid, setIsSubmissionValid] = React.useState(false);
	const [isCompletedMsg, setIscompletedMsg] = React.useState('');
	const [error, setError] = React.useState('');

	function mapUserToAssociatedShow(selectedUserId) {
		try {
			setSelectedUser(selectedUserId);
			const { associatedShow } = users.filter((x) => x.value === selectedUserId)[0];
			if (associatedShow) {
				setSelectedShow(associatedShow);
			} else {
				setSelectedShow('default');
			}
		} catch (e) {
			setSelectedShow('default');
		}
	}

	async function getUsers(unmounted = false) {
		const url = `${config.api_path}/users/`;
		const response = await fetch(url, {
			method: 'GET',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
		});
		const body = await response.json();
		if (!unmounted) {
			setUsers(
				body.map(({ _id, userType, username, associatedShow }) => ({
					label: username,
					value: _id,
					userType,
					associatedShow,
				}))
			);
			setLoading(false);
		}
	}

	async function apiChangeUserAssociatedShow(body) {
		const url = `${config.api_path}/users/${body._id}`;
		const response = await fetch(url, {
			method: 'PUT',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		});
		if (response.status === 200) {
			const json = await response.json();
			setIscompletedMsg(` The user "${json.username}"  is now associated with the show  ${json.associatedShow}`);
			setError(``);
			getUsers(false);
		} else {
			setIscompletedMsg(``);
			setError(`It looks like we have a an error, ooops ${response.status}`);
		}
	}

	useEffect(() => {
		let unmounted = false;
		getUsers(unmounted);
		return () => {
			unmounted = true;
		};
	}, []);

	useEffect(() => {
		if (selectedShow && selectedUser) {
			setIsSubmissionValid(true);
		}
	}, [isSubmissionValid, selectedShow, selectedUser]);

	if (loading) return <span>Loading</span>;

	return (
		<div className={styles.section}>
			<span>Select User </span>
			<select
				disabled={loading}
				value={selectedUser}
				onChange={(e) => mapUserToAssociatedShow(e.currentTarget.value, users)}
			>
				<option key="-1" value="-1">
					Select User
				</option>
				{users.map((item) => (
					<option key={item.value} value={item.value}>
						{item.label}
					</option>
				))}
			</select>
			<select
				style={{ marginLeft: '1em' }}
				disabled={loading}
				onChange={(e) => setSelectedShow(e.currentTarget.value)}
				value={selectedShow}
			>
				{shows.map((item) => (
					<option key={item.name} value={item.name}>
						{`${item.name}`}
					</option>
				))}
			</select>

			<div>
				<button
					style={{ marginTop: '0.5em' }}
					type="submit"
					onClick={() =>
						apiChangeUserAssociatedShow({
							_id: selectedUser,
							associatedShow: selectedShow,
						})
					}
					disabled={!isSubmissionValid}
				>
					Submit
				</button>
				<span>{error}</span>
				<span style={{ color: 'green' }}> {isCompletedMsg}</span>
			</div>
		</div>
	);
}
