import React from "react";
import PropTypes from "prop-types";
import Marquee from "../../lib/Marquee";
import styles from "./BackToLive.module.css";

/**
 * @author  Conan Lai
 * @description displays on mobile to allow user to return to live player
 * from archive player
 */

class BackToLive extends React.Component {
  render() {
    return (
      <div className={styles.backtolive} onClick={this.props.playLive}>
        <Marquee>BACK TO LIVE // BACK TO LIVE // BACK TO LIVE</Marquee>
      </div>
    );
  }
}

export default BackToLive;
