import React from "react";
import PropTypes from "prop-types";
import styles from "./EventTicker.module.css";

/**
 * @author  Conan Lai
 * @description Single Event in bottom ticker
 */

class Event extends React.PureComponent {
  render() {
    const { startDate, name } = this.props;
    const date = new Date(startDate);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const style = this.props.special
      ? styles.event + " " + styles.special
      : styles.event;
    return (
      <a
        className={style}
        href={this.props.url}
        rel="noopener noreferrer"
        target="_blank"
      >
        {month}/{day} – {name}
      </a>
    );
  }
}

Event.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  special: PropTypes.bool,
};

export default Event;
