import React from 'react';
import styles from '../AdminEditor.module.css';
import config from '../../../../config';

import DateTime from 'react-datetime';

class EditNewEvent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			url: '',
			startDate: new Date(),
			endDate: new Date(),
			special: false,
		};
		this.handleName = this.handleName.bind(this);
		this.handleUrl = this.handleUrl.bind(this);
		this.handleStartDate = this.handleStartDate.bind(this);
		this.handleEndDate = this.handleEndDate.bind(this);
		this.handleSpecial = this.handleSpecial.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.updateData = this.updateData.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	componentDidMount() {
		this.mounted = true;
		if (this.props.editMode === 'edit') {
			this.updateData();
		}
	}

	// we use this pattern to refetch data if user selects a different one to edit
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedNewEventId !== this.props.selectedNewEventId) {
			this.updateData();
		}
		if (prevProps.editMode === 'edit' && this.props.editMode === 'add') {
			this.setState({
				name: '',
				url: '',
				startDate: new Date(),
				endDate: new Date(),
				special: false,
			});
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	handleName(event) {
		this.setState({
			name: event.target.value,
		});
	}

	handleUrl(event) {
		this.setState({
			url: event.target.value,
		});
	}

	handleStartDate(startDate) {
		this.setState({
			startDate,
		});
	}

	handleEndDate(endDate) {
		this.setState({
			endDate,
		});
	}

	handleSpecial(event) {
		this.setState({
			special: !this.state.special,
		});
	}

	handleDelete() {
		fetch(`${config.api_path}/new-events/${this.state.id}`, {
			method: 'delete',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				if (response.status === 401) {
					this.props.signOut();
				} else {
					return response.json();
				}
			})
			.then((json) => {
				console.log(json);
				this.props.handleRefresh();
			})
			.catch((error) => {
				console.log('Request failed', error);
			});
	}

	handleSubmit() {
		const editMode = this.props.editMode;
		const url =
			editMode === 'edit'
				? `${config.api_path}/new-events/${this.props.selectedNewEventId}`
				: `${config.api_path}/new-events`;
		const fetchMode = editMode === 'edit' ? 'put' : 'post';
		const payload = {
			name: this.state.name,
			url: this.state.url,
			startDate: this.state.startDate.valueOf(),
			endDate: this.state.endDate.valueOf(),
			special: this.state.special,
		};

		const payloadJSON = JSON.stringify(payload);
		fetch(url, {
			method: fetchMode,
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
			body: payloadJSON,
		})
			.then((response) => {
				if (response.status === 401) {
					this.props.signOut();
				} else {
					return response.json();
				}
			})
			.then((json) => {
				console.log(json);
				this.props.handleRefresh();
			})
			.catch((error) => {
				console.log('Request failed', error);
			});
	}

	updateData() {
		fetch(`${config.api_path}/new-events/${this.props.selectedNewEventId}`)
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				// this line is here because the server returns an array even if there is only
				// one show with given slug
				const newEvent = json;
				if (this.mounted === true) {
					this.setState({
						name: newEvent.name,
						url: newEvent.url,
						startDate: new Date(newEvent.startDate),
						endDate: new Date(newEvent.endDate),
						special: newEvent.special,
					});
				}
			});
	}

	render() {
		const deletebutton =
			this.props.editMode === 'edit' ? (
				<div>
					<button className={styles.button} onClick={this.handleDelete}>
						delete this
					</button>
				</div>
			) : null;
		return (
			<div>
				<div className={styles.section}>
					<span className={styles.label}>event name: </span>
					<input value={this.state.name} onChange={this.handleName} className={styles.input} />
				</div>
				<div className={styles.section}>
					<span className={styles.label}>event url: </span>
					<input value={this.state.url} onChange={this.handleUrl} className={styles.input} />
				</div>
				<div className={styles.section}>
					<br />
					start date will be the date displayed on the website, end date (and time) will be the time it will
					be shown on the site until
					<br />
					<br />
					<span className={styles.label}>start date + time: </span>
					<DateTime onChange={this.handleStartDate} value={this.state.startDate} />
				</div>
				<div className={styles.section}>
					<span className={styles.label}>end date + time: </span>
					<DateTime onChange={this.handleEndDate} value={this.state.endDate} />
				</div>
				<div className={styles.section}>
					<br />
					<input name="special" type="checkbox" checked={this.state.special} onChange={this.handleSpecial} />
					Check here if this is a special event, such as a Mutek sponsored show or an n10.as night. It will
					show as highlighted on website.
				</div>
				<div className={styles.section}>
					{deletebutton}
					<div>
						<button className={styles.button} onClick={this.handleSubmit}>
							submit this
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default EditNewEvent;
