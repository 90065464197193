import config from "../../config";

export default function reducer(
  state = {
    backgrounds: {},
    selectedBackground: "",
  },
  action = {}
) {
  switch (action.type) {
    case "CHANGE_BACKGROUND":
      return {
        ...state,
        selectedBackground: action.value,
        backgrounds: {
          ...state.backgrounds,
          [action.value]: {
            ...state.backgrounds[action.value],
            loaded: true,
          },
        },
      };
    case "REQUEST_BACKGROUNDS":
      return {
        ...state,
        isFetching: true,
      };
    case "RECEIVE_BACKGROUNDS":
      return {
        ...state,
        isFetching: false,
        backgrounds: action.backgrounds,
      };
    default:
      return state;
  }
}

export const changeBackground = (bg) => {
  return {
    type: "CHANGE_BACKGROUND",
    value: bg,
  };
};

export const requestBackgrounds = () => {
  return {
    type: "REQUEST_BACKGROUNDS",
  };
};

export const receiveBackgrounds = (json) => {
  return {
    type: "RECEIVE_BACKGROUNDS",
    backgrounds: json,
  };
};

export const receiveBackgroundsError = (err) => {
  return {
    type: "RECEIVE_BACKGROUNDS_ERROR",
    error: err,
  };
};

export const fetchBackgrounds = (timeout = 1) => {
  return (dispatch) => {
    dispatch(requestBackgrounds());
    return fetch(`${config.api_path}/backgrounds`)
      .then((response) => {
        const status = response.status;
        switch (status) {
          case 200:
            return response.json();
          default:
            return Promise.reject(status);
        }
      })
      .then((json) => {
        const backgrounds = {};
        json.forEach((bg) => {
          backgrounds[bg._id] = { ...bg, loaded: false };
        });
        dispatch(receiveBackgrounds(backgrounds));
        const keys = Object.keys(backgrounds);
        const randkey = keys[Math.floor(Math.random() * keys.length)];
        const randbg = backgrounds[randkey];

        // select bg once loaded
        const img = new Image();
        img.src = randbg.url;
        img.onload = () => {
          dispatch(changeBackground(randkey));
        };
      })
      .catch((err) => {
        dispatch(receiveBackgroundsError(err));
        setTimeout(() => {
          dispatch(fetchBackgrounds(timeout + 0.25));
        }, Math.pow(1000, timeout));
      });
  };
};
