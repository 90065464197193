import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./BackgroundPicker.module.css";

import { changeBackground } from "../../../redux/modules/background";

/**
 * @author  Conan Lai
 * @description allows users to select the background
 */

class BackgroundPicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.loadAndSelectBg = this.loadAndSelectBg.bind(this);
  }

  loadAndSelectBg(key) {
    if (this.props.backgrounds[key].loaded === true) {
      this.props.changeBackground(key);
    } else {
      // select bg once loaded
      const img = new Image();
      img.src = this.props.backgrounds[key].url;
      img.onload = () => {
        this.props.changeBackground(key);
      };
    }
  }

  render() {
    return (
      <div className={styles.backgroundpicker}>
        {Object.keys(this.props.backgrounds).map((key) => {
          const bg = this.props.backgrounds[key];
          return (
            <div
              key={key}
              className={styles.backgrounditem}
              style={{ backgroundImage: `url('${bg.thumbnail}')` }}
              onClick={() => this.loadAndSelectBg(key)}
            />
          );
        })}
      </div>
    );
  }
}

BackgroundPicker.propTypes = {
  backgrounds: PropTypes.object,
  changeBackground: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    backgrounds: state._background.backgrounds,
  };
};

const ConnectedBackgroundPicker = connect(mapStateToProps, {
  changeBackground,
})(BackgroundPicker);

export default ConnectedBackgroundPicker;
