import React, { useEffect } from 'react';
import config from '../../../../config';
import styles from '../AdminEditor.module.css';

const userTypesArray = [
	{ value: 'show-host', label: 'show-host' },
	{ value: 'guest', label: 'guest ' },
	{ value: 'admin', label: 'admin' },
];

export default function UserAdminRole() {
	const [loading, setLoading] = React.useState(true);
	const [userTypes] = React.useState(userTypesArray);
	const [selectedUserType, setSelectedUserType] = React.useState('');
	const [users, setUsers] = React.useState([]);
	const [selectedUser, setSelectedUser] = React.useState('');
	const [isSubmissionValid, setIsSubmissionValid] = React.useState(false);
	const [isCompletedMsg, setIscompletedMsg] = React.useState('');
	const [error, setError] = React.useState('');

	function mapUserToSelectedUserType(name, users) {
		try {
			const { userType, value } = users.filter((x) => x.value === name)[0];
			setSelectedUser(value);
			setSelectedUserType(userType);
		} catch (err) {
			setSelectedUser(false);
			setSelectedUserType(false);
		}
	}

	async function getUsers(unmounted = false) {
		const url = `${config.api_path}/users/`;
		const response = await fetch(url, {
			method: 'GET',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
		});
		const body = await response.json();
		if (!unmounted) {
			setUsers(
				body.map(({ _id, userType, username }) => ({
					label: username,
					value: _id,
					userType,
				}))
			);
			setLoading(false);
		}
	}

	async function apiChangeUserRole(body) {
		const url = `${config.api_path}/users/role/${body._id}`;
		const response = await fetch(url, {
			method: 'PUT',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		});
		if (response.status === 200) {
			const json = await response.json();
			setIscompletedMsg(` ${json.username} user permissions have been updated to ${json.userType}`);
			setError(``);
			getUsers(false);
		} else {
			setIscompletedMsg(``);
			setError(`It looks like we have a an error, ooops ${response.status}`);
		}
	}

	useEffect(() => {
		let unmounted = false;
		getUsers(unmounted);
		return () => {
			unmounted = true;
		};
	}, []);

	useEffect(() => {
		if (selectedUserType && selectedUser) {
			setIsSubmissionValid(true);
		}
		// setIsSubmissionValid(false);
	}, [isSubmissionValid, selectedUser, selectedUserType]);

	if (loading) return <span>Loading</span>;

	return (
		<React.Fragment>
			<div className={styles.section}>
				<span>Assign User To Role: </span>
				<select
					disabled={loading}
					value={selectedUser}
					onChange={(e) => mapUserToSelectedUserType(e.currentTarget.value, users)}
				>
					<option key="-1" value="-1">
						Select User
					</option>
					{users.map((item) => (
						<option key={item.value} value={item.value}>
							{item.label}
						</option>
					))}
				</select>
				<select
					style={{ marginLeft: '1em' }}
					disabled={loading}
					onChange={(e) => setSelectedUserType(e.currentTarget.value)}
					value={selectedUserType}
				>
					{userTypes.map((item) => (
						<option key={item.value} value={item.value}>
							{item.label}
						</option>
					))}
				</select>

				<div>
					<button
						style={{ marginTop: '0.5em' }}
						type="submit"
						onClick={() =>
							apiChangeUserRole({
								_id: selectedUser,
								userType: selectedUserType,
							})
						}
						disabled={!isSubmissionValid}
					>
						Submit
					</button>
					<span>{error}</span>
					<span style={{ color: 'green' }}> {isCompletedMsg}</span>
				</div>
			</div>
		</React.Fragment>
	);
}
