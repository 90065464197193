export default function reducer(state = {
  current: '',
  next: '',
}, action = {}) {
  switch (action.type) {
    case 'REQUEST_AIRTIME_INFO':
      return {
        ...state,
        isFetching: true,
      };
    case 'RECEIVE_AIRTIME_INFO':
      return {
        ...state,
        isFetching: false,
        current: action.current,
        next: action.next,
      };
    default:
      return state;
  }
}

export const requestAirtimeInfo = () => {
  return {
    type: 'REQUEST_AIRTIME_INFO',
  };
};

export const receiveAirtimeInfo = (json) => {
  const current = json.currentShow[0].name;
  const next = json.nextShow[0].name;
  return {
    type: 'RECEIVE_AIRTIME_INFO',
    current,
    next,
  };
};

export const receiveAirtimeInfoError = (err) => {
  return {
    type: 'RECEIVE_AIRTIME_INFO_ERROR',
    error: err,
  };
};

export const fetchAirtimeInfo = () => {
  return (dispatch) => {
    dispatch(requestAirtimeInfo());
    return fetch('https://n10as.airtime.pro/api/live-info/').then((response) => {
      const status = response.status;
      switch (status) {
        case 200:
          return response.json();
        default:
          return Promise.reject(status);
      }
    }).then((json) => {
      dispatch(receiveAirtimeInfo(json));
    }).catch(err => {
      dispatch(receiveAirtimeInfoError(err));
    });
  };
};

