import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import AdminContainer from '../../components/Admin/AdminContainer/AdminContainer';

import { signOut } from '../../redux/modules/user';

function Admin({ authenticated, email, userType, signOut }) {
	useEffect(() => {
		/// Fetch back end resources with token from localstorage
	}, []);
	return (
		<React.Fragment>
			<AdminContainer email={email} userType={userType} signOut={signOut} />
			{!authenticated ? <Redirect to="/login" /> : null}
		</React.Fragment>
	);
}

const mapStateToProps = (state) => {
	return {
		user: state._user,
		authenticated: state._user.authenticated,
		coginitoUserLoading: state._user.isUserCognitoSessionLoading,
		cognitoUserError: state._user.cognitoUserError,
		email: state._user.email,
		userType: state._user.userType,
	};
};

const ConnectedAdmin = connect(mapStateToProps, {
	signOut,
})(withRouter(Admin));

export default ConnectedAdmin;
