import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./AudioPlayer.module.css";
import config from "../../../config";
import PlayPause from "./PlayPause";

import { playLive, pauseLive } from "../../../redux/modules/audio";

/**
 * @author  Conan Lai
 * @description uses React 'ref' to control html audio element
 */

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.updateIsPlaying = this.updateIsPlaying.bind(this);
    this.toggleLive = this.toggleLive.bind(this);
  }

  async componentDidMount() {
    if (!this.props.isMobile) {
      const promise = this.node.play();
      if (promise !== undefined) {
        try {
          await promise;
          this.props.playLive();
        } catch (e) {
          // do nothing
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.source !== this.props.source) {
      this.updateIsPlaying();
    }
    if (prevProps.volume !== this.props.volume) {
      const node = this.node;
      node.volume = this.props.volume / 100;
    }
  }

  updateIsPlaying() {
    const node = this.node;
    const isPlaying = this.props.source === "live";
    if (isPlaying) {
      node.load();
      node.play();
    } else {
      node.pause();
    }
  }

  toggleLive() {
    const node = this.node;
    const isPlaying = this.props.source === "live";
    if (!isPlaying) {
      node.play();
      this.props.playLive();
    } else {
      node.pause();
      this.props.pauseLive();
    }
  }

  render() {
    const button =
      (this.props.source === "archive" ||
        this.props.source === "pausedarchive") &&
      this.props.isMobile ? null : (
        <PlayPause toggleLive={this.toggleLive} />
      );
    return (
      <div
        className={styles.audioplayer}
        style={{ display: this.props.source !== "archives" ? "block" : "none" }}
      >
        {button}
        <audio id="player" ref={(node) => (this.node = node)} controls={false}>
          <source src={config.stream_url} />
        </audio>
      </div>
    );
  }
}

AudioPlayer.propTypes = {
  source: PropTypes.string,
  volume: PropTypes.number,
  playLive: PropTypes.func,
  pauseLive: PropTypes.func,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    source: state._audio.source,
    volume: state._audio.volume,
    isMobile: state._ui.isMobile,
  };
};

const ConnectedAudioplayer = connect(mapStateToProps, {
  playLive,
  pauseLive,
})(AudioPlayer);

export default ConnectedAudioplayer;
