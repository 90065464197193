import React from 'react';
import AdminEditor from '../AdminEditor/AdminEditor';
import styles from './AdminContainer.module.css';
import { Link } from 'react-router-dom';

import config from '../../../config';

import './Select.css';
// Admin is entry point for the Admin component tree

function filterEvents(events) {
	const now = new Date().getTime();
	return events
		.filter((event) => {
			const isInfuture = event.endDate > now;
			return isInfuture;
		})
		.sort((a, b) => {
			return a.endDate - b.endDate;
		});
}

class AdminContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			shows: [],
			selectedShow: '',
			events: [],
			shop: [],
			announcements: [],
			newEvents: [],
			selectedEventId: '',
			selectedNewEventId: '',
			selectedShopId: '',
			selectedAnnouncementId: '',
			editorMode: '',
		};
		this.handleAddShow = this.handleAddShow.bind(this);
		this.handleAddNewEvent = this.handleAddNewEvent.bind(this);
		this.handleAddShop = this.handleAddShop.bind(this);
		this.handleAddAnnouncement = this.handleAddAnnouncement.bind(this);
		this.handleShowSelect = this.handleShowSelect.bind(this);
		this.handleNewEventSelect = this.handleNewEventSelect.bind(this);
		this.handleShopSelect = this.handleShopSelect.bind(this);
		this.handleAnnouncementSelect = this.handleAnnouncementSelect.bind(this);
		this.fetchShows = this.fetchShows.bind(this);
		this.fetchEvents = this.fetchEvents.bind(this);
		this.fetchShop = this.fetchShop.bind(this);
		this.fetchNewEvents = this.fetchNewEvents.bind(this);
		this.fetchAnnouncements = this.fetchAnnouncements.bind(this);
		this.handleEditBackgrounds = this.handleEditBackgrounds.bind(this);
		this.handleGetInstagramAccess = this.handleGetInstagramAccess.bind(this);
		this.sendInstagramAccessRequest = this.sendInstagramAccessRequest.bind(this);
		this.handleRefresh = this.handleRefresh.bind(this);
		this.handledAssociateShowWithUser = this.handledAssociateShowWithUser.bind(this);
		this.handleUserAdminRole = this.handleUserAdminRole.bind(this);
		this.handleUploadShow = this.handleUploadShow.bind(this);
		this.handleCreateUser = this.handleCreateUser.bind(this);
		this.scrollEditorIntoView = this.scrollEditorIntoView.bind(this);
	}

	componentDidMount() {
		this.mounted = true;
		// call handleRefresh with no argument to fetch initial data
		this.handleRefresh();
		const code = window.location.search.split('code=')[1];
		if (code) {
			this.sendInstagramAccessRequest(code);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.userType !== this.props.userType) {
			this.handleRefresh();
		}
	}

	componentWillUnmount() {
		// we use this pattern to prevent calling setState() after component unmounts
		this.mounted = false;
	}

	scrollEditorIntoView() {
		const adminEditor = document.querySelector('#admin-editor');
		adminEditor.scrollIntoView();
	}

	fetchShows() {
		fetch(`${config.api_path}/shows`)
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (this.mounted === true) {
					this.setState({ shows: [{ name: '--- SELECT A SHOW TO EDIT ---', _id: 'default' }].concat(json) });
				}
			});
	}

	fetchEvents() {
		fetch(`${config.api_path}/events`)
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (this.mounted === true) {
					this.setState({
						events: [{ name: '--- SELECT AN EVENT TO EDIT ---', _id: 'default' }].concat(json),
					});
				}
			});
	}

	fetchNewEvents() {
		fetch(`${config.api_path}/new-events`)
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (this.mounted === true) {
					this.setState({
						newEvents: [{ name: '--- SELECT AN EVENT TO EDIT ---', _id: 'default' }].concat(
							filterEvents(json)
						),
					});
				}
			});
	}

	fetchShop() {
		fetch(`${config.api_path}/shop`)
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (this.mounted === true) {
					this.setState({
						shop: [{ name: '--- SELECT A SHOP ITEM TO EDIT ---', _id: 'default' }].concat(json),
					});
				}
			});
	}

	fetchAnnouncements() {
		fetch(`${config.api_path}/announcements`)
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (this.mounted === true) {
					this.setState({
						announcements: [{ name: '--- SELECT AN ANNOUNCEMENT TO EDIT ---', _id: 'default' }].concat(
							json
						),
					});
				}
			});
	}

	handleShowSelect(event) {
		const id = event.target.value;
		if (id !== 'default') {
			this.setState({
				selectedShow: id,
				editorMode: 'EditShow',
			});
		}
		this.scrollEditorIntoView();
	}

	handleNewEventSelect(event) {
		const id = event.target.value;
		if (id !== 'default') {
			this.setState({
				selectedNewEventId: event.target.value,
				editorMode: 'EditNewEvent',
			});
		}
		this.scrollEditorIntoView();
	}

	handleShopSelect(event) {
		const id = event.target.value;
		if (id !== 'default') {
			this.setState({
				selectedShopId: id,
				editorMode: 'EditShop',
			});
		}
		this.scrollEditorIntoView();
	}

	handleAnnouncementSelect(event) {
		const id = event.target.value;
		if (id !== 'default') {
			this.setState({
				selectedAnnouncementId: id,
				editorMode: 'EditAnnouncement',
			});
		}
		this.scrollEditorIntoView();
	}

	handleAddShow() {
		this.setState({
			editorMode: 'AddShow',
		});
		this.scrollEditorIntoView();
	}

	handleAddNewEvent() {
		this.setState({
			editorMode: 'AddNewEvent',
		});
		this.scrollEditorIntoView();
	}

	handleUserAdminRole() {
		this.setState({
			editorMode: 'EditUserAdminRole',
		});
		this.scrollEditorIntoView();
	}

	handleUploadShow() {
		this.setState({
			editorMode: 'AddUploadShow',
		});
		this.scrollEditorIntoView();
	}

	handleCreateUser() {
		this.setState({
			editorMode: 'CreateUser',
		});
		this.scrollEditorIntoView();
	}

	handledAssociateShowWithUser() {
		this.setState({
			editorMode: 'EditAssociateShowWithUser',
		});
		this.scrollEditorIntoView();
	}

	handleAddShop() {
		this.setState({
			editorMode: 'AddShop',
		});
		this.scrollEditorIntoView();
	}

	handleAddAnnouncement() {
		this.setState({
			editorMode: 'AddAnnouncement',
		});
		this.scrollEditorIntoView();
	}

	handleEditBackgrounds() {
		this.setState({
			editorMode: 'EditBackgrounds',
		});
		this.scrollEditorIntoView();
	}

	handleGetInstagramAccess() {
		this.setState({
			editorMode: 'GetInstagramAccess',
		});
		this.scrollEditorIntoView();
	}

	sendInstagramAccessRequest(code) {
		fetch(`${config.api_path}/instagram/token`, {
			method: 'post',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				if (response.status === 401) {
					this.props.signOut();
				} else {
					return response.json();
				}
			})
			.then((json) => {
				this.handleRefresh();
			})
			.catch((error) => {
				this.setState({
					error,
				});
			});
	}

	// handleRefresh passed down and called after user submits an action
	// redirect argument used for certain actions that require different redirect pages
	handleRefresh(redirect) {
		this.fetchShows();
		if (this.props.userType === 'admin') {
			this.fetchEvents();
			this.fetchNewEvents();
			this.fetchShop();
			this.fetchAnnouncements();
			this.setState({
				editorMode: redirect || '',
				selectedEventId: '',
				selectedShopId: '',
				selectedNewEventId: '',
				selectedAnnouncementId: '',
			});
		}
	}

	renderShowHostComponents() {
		return (
			<React.Fragment>
				<div className={styles.contenttypecontainer}>
					<button onClick={this.handleUploadShow}>Upload Show From Google Drive</button>
				</div>
				<div className={styles.contenttypecontainer}>
					<Link to="/uploads-queue">
						<button>View Uploads Queue Status</button>
					</Link>
				</div>
				<div id="admin-editor">
					<AdminEditor
						userType={this.props.userType}
						handleRefresh={this.handleRefresh}
						editorMode={this.state.editorMode}
						selectedShow={this.state.selectedShow}
						selectedEventId={this.state.selectedEventId}
						selectedShopId={this.state.selectedShopId}
						selectedAnnouncementId={this.state.selectedAnnouncementId}
						selectedNewEventId={this.state.selectedNewEventId}
						signOut={this.props.signOut}
						shows={this.state.shows}
					/>
				</div>
			</React.Fragment>
		);
	}

	renderAdminComponents() {
		return (
			<React.Fragment>
				<div className={styles.contenttypecontainer}>
					<button onClick={this.handleCreateUser}>Create User</button>
				</div>
				<div className={styles.contenttypecontainer}>
					<button onClick={this.handleUserAdminRole}>Change User Role</button>
				</div>
				<div className={styles.contenttypecontainer}>
					<button onClick={this.handledAssociateShowWithUser}>Associate Show With User</button>
				</div>
				<div className={styles.contenttypecontainer}>
					<button onClick={this.handleUploadShow}>Upload Show From Google Drive</button>
				</div>
				<div className={styles.contenttypecontainer}>
					<Link to="/uploads-queue">
						<button>View Uploads Queue Status</button>
					</Link>
				</div>
				<div className={styles.contenttypecontainer}>
					<button onClick={this.handleAddShow}>ADD A SHOW</button>
					<select value={this.state.selectedShow} onChange={this.handleShowSelect}>
						{this.state.shows.map((show, i) => {
							return (
								<option key={i} value={show.slug}>
									{show.name}
								</option>
							);
						})}
					</select>
				</div>
				<div className={styles.contenttypecontainer}>
					<button onClick={this.handleAddNewEvent}>ADD AN EVENT</button>
					<select value={this.state.selectedNewEvent} onChange={this.handleNewEventSelect}>
						{this.state.newEvents.map((event, i) => {
							return (
								<option key={i} value={event._id}>
									{event.name}
								</option>
							);
						})}
					</select>
				</div>{' '}
				<div className={styles.contenttypecontainer}>
					<button onClick={this.handleAddShop}>ADD A SHOP ITEM</button>
					<select value={this.state.selectedShopId} onChange={this.handleShopSelect}>
						{this.state.shop.map((shopitem, i) => {
							return (
								<option key={i} value={shopitem._id}>
									{shopitem.name}
								</option>
							);
						})}
					</select>
				</div>
				<div className={styles.contenttypecontainer}>
					<button onClick={this.handleAddAnnouncement}>ADD AN ANNOUNCEMENT</button>
					<select value={this.state.selectedAnnouncementId} onChange={this.handleAnnouncementSelect}>
						{this.state.announcements.map((announcement, i) => {
							return (
								<option key={i} value={announcement._id}>
									{announcement.name}
								</option>
							);
						})}
					</select>
				</div>
				<div className={styles.contenttypecontainer}>
					<button onClick={this.handleEditBackgrounds}>EDIT BACKGROUNDS</button>
				</div>
				<div className={styles.contenttypecontainer}>
					<button onClick={this.handleGetInstagramAccess}>GET INSTAGRAM ACCESS TOKEN</button>
				</div>
				<div id="admin-editor">
					<AdminEditor
						userType={this.props.userType}
						handleRefresh={this.handleRefresh}
						editorMode={this.state.editorMode}
						selectedShow={this.state.selectedShow}
						selectedEventId={this.state.selectedEventId}
						selectedShopId={this.state.selectedShopId}
						selectedAnnouncementId={this.state.selectedAnnouncementId}
						selectedNewEventId={this.state.selectedNewEventId}
						signOut={this.props.signOut}
						shows={this.state.shows}
					/>
				</div>
			</React.Fragment>
		);
	}

	renderMainComponents() {
		switch (this.props.userType) {
			case 'admin':
				return this.renderAdminComponents();
			case 'show-host':
				return this.renderShowHostComponents();
			default:
				return null;
		}
	}

	render() {
		return (
			<div className={styles.background}>
				<div className={styles.header}>
					<pre>
						{`
███╗   ██╗ ██╗ ██████╗     █████╗ ███████╗     ██████╗███╗   ███╗███████╗
████╗  ██║███║██╔═████╗   ██╔══██╗██╔════╝    ██╔════╝████╗ ████║██╔════╝
██╔██╗ ██║╚██║██║██╔██║   ███████║███████╗    ██║     ██╔████╔██║███████╗
██║╚██╗██║ ██║████╔╝██║   ██╔══██║╚════██║    ██║     ██║╚██╔╝██║╚════██║
██║ ╚████║ ██║╚██████╔╝██╗██║  ██║███████║    ╚██████╗██║ ╚═╝ ██║███████║
╚═╝  ╚═══╝ ╚═╝ ╚═════╝ ╚═╝╚═╝  ╚═╝╚══════╝     ╚═════╝╚═╝     ╚═╝╚══════╝
          `}
					</pre>
				</div>
				<div className={styles.subheader}>
					<center>
						welcome {this.props.email}
						<br />
						{this.props.userType}
						<br />
						<button className={styles.logoutbutton} onClick={() => this.props.signOut()}>
							logout
						</button>
					</center>
				</div>
				<div className={styles.admin}>{this.renderMainComponents()}</div>
			</div>
		);
	}
}

export default AdminContainer;
