import * as Yup from "yup";

export const validationSchema = Yup.object({
  email: Yup.string().label("Email").email().required(),
  password: Yup.string().required("Password is Required! "),
});

export const initialValues = {
  email: "",
  password: "",
};
