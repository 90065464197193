import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./ArchiveShow.module.css";

import { changeArchive } from "../../../../redux/modules/archives";

/**
 * @author  Conan Lai
 * @description displays an archive row, splitting the name to seperate
 * name and date - if name is too long, displays a marquee instead of <span>
 */

class ArchiveShow extends React.PureComponent {
  render() {
    const { slug, name } = this.props;
    const title = name.slice(0, -11);
    const date = name.slice(-10);

    return (
      <div
        className={styles.row}
        onClick={() => {
          window.open(`https://www.mixcloud.com${slug}`, "_blank");
          this.props.closeArchive();
        }}
      >
        <div className={styles.date}>{date}</div>
        <div className={styles.name}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div className={styles.clear} />
      </div>
    );
  }
}

ArchiveShow.propTypes = {
  slug: PropTypes.string,
  name: PropTypes.string,
  changeArchive: PropTypes.func,
  closeArchive: PropTypes.func,
};

const ConnectedArchiveShow = connect(null, { changeArchive })(ArchiveShow);

export default ConnectedArchiveShow;
