export default function reducer(state = {
  source: 'none',
  volume: 100,
}, action = {}) {
  switch (action.type) {
    case 'PLAY_LIVE':
      return {
        ...state,
        source: 'live',
      };
    case 'PAUSE_LIVE':
      return {
        ...state,
        source: 'pausedlive',
      };
    case 'CHANGE_ARCHIVE':
      return {
        ...state,
        source: 'archive',
      };
    case 'PLAY_ARCHIVE':
      return {
        ...state,
        source: 'archive',
      };
    case 'PAUSE_ARCHIVE':
      return {
        ...state,
        source: 'pausedarchive',
      };
    case 'CHANGE_VOLUME':
      return {
        ...state,
        volume: action.volume,
      };
    default:
      return state;
  }
}

export const playLive = () => {
  return {
    type: 'PLAY_LIVE',
  };
};

export const pauseLive = () => {
  return {
    type: 'PAUSE_LIVE',
  };
};

export const playArchive = () => {
  return {
    type: 'PLAY_ARCHIVE',
  };
};

export const pauseArchive = () => {
  return {
    type: 'PAUSE_ARCHIVE',
  };
};

export const changeVolume = (volume) => {
  return {
    type: 'CHANGE_VOLUME',
    volume,
  };
};
