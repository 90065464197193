import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./DeskTop.module.css";

import Loadable from "react-loadable";

const Loading = () => <div></div>;

const LoadableBulletinBoard = Loadable({
  loader: () => import("./Contents/BulletinBoard/BulletinBoardHolder"),
  loading: Loading,
});

const LoadableChat = Loadable({
  loader: () => import("./Contents/Chat/ChatHolder"),
  loading: Loading,
});

const LoadableAboutHolder = Loadable({
  loader: () => import("./Contents/About/AboutHolder"),
  loading: Loading,
});

const LoadableInstagramHolder = Loadable({
  loader: () => import("./Contents/Instagram/InstagramHolder"),
  loading: Loading,
});
const LoadableShopHolder = Loadable({
  loader: () => import("./Contents/Shop/ShopHolder"),
  loading: Loading,
});

const LoadableShowsHolder = Loadable({
  loader: () => import("././Contents/Shows/ShowsHolder"),
  loading: Loading,
});

const LoadableShowHolder = Loadable({
  loader: () => import("././Contents/Show/ShowHolder"),
  loading: Loading,
});

const LoadableMobileArchivesAndSchedule = Loadable({
  loader: () =>
    import("././MobileArchivesAndSchedule/MobileArchivesAndSchedule"),
  loading: Loading,
});

/**
 * @author  Conan Lai
 * @description Desktop component, once the user's window size has been found, inserts all the
 * content windows using the AsyncComponent - the JS bundles are fetched only when required
 */

class DeskTop extends React.Component {
  render() {
    // we don't mount these children until windowSize has been determined
    // including mobile status, as those inform the initial placement of windows
    const desktopcontent = this.props.foundWindowSize ? (
      <div className={styles.innerdesktop}>
        <LoadableBulletinBoard />
        <LoadableAboutHolder />
        <LoadableInstagramHolder />
        <LoadableShopHolder />
        <LoadableShowsHolder />
        <LoadableChat />
        <LoadableShowHolder />
      </div>
    ) : null;
    const mobilearchivesschedule = this.props.isMobile ? (
      <LoadableMobileArchivesAndSchedule />
    ) : null;
    return (
      <div className={styles.desktop}>
        {desktopcontent}
        {mobilearchivesschedule}
      </div>
    );
  }
}

DeskTop.propTypes = {
  foundWindowSize: PropTypes.bool,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    foundWindowSize: state._ui.foundWindowSize,
    isMobile: state._ui.isMobile,
  };
};

const ConnectedDeskTop = connect(mapStateToProps)(DeskTop);

export default ConnectedDeskTop;
