import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "../SideBar.module.css";

import icon from "../imgs/icons_bulletins.png";

import { bringBulletinBoardToFront } from "../../../redux/modules/content/bulletinboard";

import { incrementTopZ } from "../../../redux/modules/ui";

/**
 * @author  Francis Goodship and Conan Lai
 * @description takes in height/width prop from Icons component, which calculates size based
 * on number of icons
 */

class BulletinIcon extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.isMobile) {
      this.props.closeAll();
    }
    const newZ = this.props.topZIndex + 1;
    this.props.bringBulletinBoardToFront(newZ);
    this.props.incrementTopZ();
  }

  render() {
    return (
      <div
        className={styles.element}
        style={{
          height: this.props.height ? `${this.props.height}%` : null,
          width: this.props.width ? `${this.props.width}%` : null,
        }}
        onClick={this.handleClick}
        data-tip="bulletin board"
      >
        <div className={styles.imgwrapper}>
          <img src={icon} />
        </div>
      </div>
    );
  }
}

BulletinIcon.propTypes = {
  isMobile: PropTypes.bool,
  closeAll: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  bringBulletinBoardToFront: PropTypes.func,
  incrementTopZ: PropTypes.func,
  topZIndex: PropTypes.number,
};

const mapPropsToState = (state) => {
  return {
    z: state._bulletinboard.z,
    topZIndex: state._ui.topZIndex,
  };
};

const ConnectedBulletinIcon = connect(mapPropsToState, {
  bringBulletinBoardToFront,
  incrementTopZ,
})(BulletinIcon);

export default ConnectedBulletinIcon;
