import React, { useEffect } from "react";
import LoginForm from "../../components/Forms/LoginForm/LoginForm";
import Background from "../../components/Background/Background";
import { connect } from "react-redux";
import { fetchBackgrounds } from "../../redux/modules/background";
import { withRouter } from "react-router-dom";

function Login({ fetchBackgrounds, authenticated }) {
  useEffect(() => {
    fetchBackgrounds();
  }, [fetchBackgrounds]);
  return (
    <div style={{ height: "100%", display: "flex" }}>
      <LoginForm />
      <Background />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isMobile: state._ui.isMobile,
    authenticated: state._user.authenticated,
  };
};

const ConnectedLogin = connect(mapStateToProps, {
  fetchBackgrounds,
})(withRouter(Login));

export default ConnectedLogin;
