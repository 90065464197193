export default function reducer(state = {
  open: 'closed',
  z: 1,
  w: 0,
  h: 0,
  x: 0,
  y: 0,
}, action = {}) {
  switch (action.type) {
    case 'CALCULATE_INSTAGRAM':
      return {
        ...state,
        x: action.x,
        y: action.y,
        w: action.w,
        h: action.h,
        open: action.open,
      };
    case 'RECALCULATE_INSTAGRAM_ON_MOBILE':
      return {
        ...state,
        x: action.x,
        y: action.y,
        w: action.w,
        h: action.h,
      };
    case 'OPEN_INSTAGRAM':
      return {
        ...state,
        open: 'open',
        z: action.z,
      };
    case 'CLOSE_INSTAGRAM':
      return {
        ...state,
        open: 'closed',
      };
    case 'BRING_INSTAGRAM_TO_FRONT':
      return {
        ...state,
        z: action.z,
        open: 'open',
      };
    default:
      return state;
  }
}

export const calculateInstagram = (params) => {
  const { isMobile, windowHeight, windowWidth, landedSlug, } = params;
  if (isMobile) {
    return {
      type: 'CALCULATE_INSTAGRAM',
      x: 0,
      y: 0,
      w: windowWidth,
      h: windowHeight - 90,
      open: 'closed',
    };
  }
  return {
    type: 'CALCULATE_INSTAGRAM',
    x: 120,
    y: 250,
    w: 350,
    h: 350,
    open: (!landedSlug) ? 'open' : 'closed',
  };
};

export const recalculateInstagramOnMobile = (params) => {
  const { windowWidth, windowHeight, } = params;
  return {
    type: 'RECALCULATE_INSTAGRAM_ON_MOBILE',
    x: 0,
    y: 0,
    w: windowWidth,
    h: windowHeight - 90,
  };
};

export const openInstagram = (z) => {
  return {
    type: 'OPEN_INSTAGRAM',
    z,
  };
};

export const closeInstagram = (e) => {
  // stop propagation here to prevent handleBringToFront from getting called
  if (e && e.target) {
    e.stopPropagation();
  }
  return {
    type: 'CLOSE_INSTAGRAM',
  };
};

export const bringInstagramToFront = (z) => {
  return {
    type: 'BRING_INSTAGRAM_TO_FRONT',
    z,
  };
};
