import React from 'react';
import styles from '../../AdminEditor/AdminEditor.module.css';

class LinkItemEdit extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={styles.section}>
        <span className={styles.label}>URL: </span>
        <input className={styles.input} onChange={this.props.handleUrl} type="text" value={this.props.link} />
        <span className={styles.label}>Description: </span>
        <input className={styles.input} onChange={this.props.handleDesc} type="text" value={this.props.name} />
      </div>
    );
  }
}

export default LinkItemEdit;
