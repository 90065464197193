import config from "../../../config";

export default function reducer(
  state = {
    open: "closed",
    z: 1,
    w: 0,
    h: 0,
    x: 0,
    y: 0,
    isFetching: false,
    shows: [],
  },
  action = {}
) {
  switch (action.type) {
    case "CALCULATE_SHOWS":
      return {
        ...state,
        x: action.x,
        y: action.y,
        w: action.w,
        h: action.h,
        open: action.open,
      };
    case "RECALCULATE_SHOWS_ON_MOBILE":
      return {
        ...state,
        x: action.x,
        y: action.y,
        w: action.w,
        h: action.h,
      };
    case "OPEN_SHOWS":
      return {
        ...state,
        open: "open",
        z: action.z,
      };
    case "CLOSE_SHOWS":
      return {
        ...state,
        open: "closed",
      };
    case "BRING_SHOWS_TO_FRONT":
      return {
        ...state,
        z: action.z,
        open: "open",
      };
    case "REQUEST_SHOWS":
      return {
        ...state,
        isFetching: true,
      };
    case "RECEIVE_SHOWS":
      return {
        ...state,
        isFetching: false,
        shows: action.shows,
      };
    default:
      return state;
  }
}

export const calculateShows = (params) => {
  const {
    isMobile,
    windowHeight,
    windowWidth,
    landedSlug,
    mobileOrientation,
  } = params;
  if (isMobile) {
    return {
      type: "CALCULATE_SHOWS",
      x: 0,
      y: 0,
      w: mobileOrientation === "landscape" ? windowWidth - 150 : windowWidth,
      h: mobileOrientation === "landscape" ? windowHeight : windowHeight - 150,
      open: "closed",
    };
  }
  const width = Math.max(windowWidth * 0.24, 400);
  const height = width;
  const x = Math.floor(windowWidth - width - 200);
  return {
    type: "CALCULATE_SHOWS",
    x,
    y: 20,
    w: width,
    h: height,
    open: !landedSlug ? "open" : "closed",
  };
};

export const recalculateShowsOnMobile = (params) => {
  const { windowWidth, windowHeight, mobileOrientation } = params;
  return {
    type: "RECALCULATE_SHOWS_ON_MOBILE",
    x: 0,
    y: 0,
    w: mobileOrientation === "landscape" ? windowWidth - 150 : windowWidth,
    h: mobileOrientation === "landscape" ? windowHeight : windowHeight - 150,
  };
};

export const openShows = (z) => {
  return {
    type: "OPEN_SHOWS",
    z,
  };
};

export const closeShows = (e) => {
  // stop propagation here to prevent handleBringToFront from getting called
  if (e && e.target) {
    e.stopPropagation();
  }
  return {
    type: "CLOSE_SHOWS",
  };
};

export const bringShowsToFront = (z) => {
  return {
    type: "BRING_SHOWS_TO_FRONT",
    z,
  };
};

export const requestShows = () => {
  return {
    type: "REQUEST_SHOWS",
  };
};

export const receiveShows = (json) => {
  return {
    type: "RECEIVE_SHOWS",
    shows: json,
  };
};

export const receiveShowsError = (err) => {
  return {
    type: "RECEIVE_SHOWS_ERROR",
    error: err,
  };
};

export const fetchShows = (timeout = 1) => {
  return (dispatch) => {
    dispatch(requestShows());
    return fetch(`${config.api_path}/shows`)
      .then((response) => {
        const status = response.status;
        switch (status) {
          case 200:
            return response.json();
          default:
            return Promise.reject(status);
        }
      })
      .then((json) => {
        dispatch(receiveShows(json));
      })
      .catch((err) => {
        dispatch(receiveShowsError(err));
        setTimeout(() => {
          dispatch(fetchShows(timeout + 0.25));
        }, Math.pow(1000, timeout));
      });
  };
};
