import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import Home from './pages/home/home';
import { connect } from 'react-redux';

import Login from './pages/login/login';
import Admin from './pages/admin/admin';
import UploadsQueue from './pages/uploadsQueue/uploadsQueue';

import RequireAuth from './components/Login/Hoc/require_auth_HOC';
import { setUser, retrieveCognitoUserFromLocalStorage } from '../src/redux/modules/user';

const App = ({ setUser, authenticated, retrieveCognitoUserFromLocalStorage }) => {
	useEffect(() => {
		const script = document.createElement('script');
		script.src = '//widget.mixcloud.com/media/js/widgetApi.js';
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	useEffect(() => {
		if (!authenticated) {
			try {
				retrieveCognitoUserFromLocalStorage();
			} catch (err) {
				console.log(err);
			}
		}
	}, [authenticated, retrieveCognitoUserFromLocalStorage]);

	return (
		<Router>
			<Switch>
				<Route path="/admin" component={RequireAuth(Admin)} />
				<Route
					exact
					path="/"
					component={() => {
						return <Home />;
					}}
				/>
				<Route
					exact
					path="/login"
					component={() => {
						return <Login />;
					}}
				/>
				<Route exact path="/uploads-queue" component={RequireAuth(UploadsQueue)} />
				<Route path="/studiobookings" component={() => {
					window.location.replace("https://calendar.google.com/calendar/u/0/appointments/AcZssZ0wl1sF9gqGgBENrrJXddjaK_J2OoMdFv0ww0k=");
					return null;
				}}/>
			</Switch>
		</Router>
	);
};

const mapStateToProps = (state) => {
	return {
		authenticated: state._user.authenticated,
	};
};

const ConnectedApp = connect(mapStateToProps, {
	setUser,
	retrieveCognitoUserFromLocalStorage,
})(App);

export default ConnectedApp;
