import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "../../SideBar.module.css";

import icon from "../../imgs/icons_archive.png";

import { openArchive, closeArchive } from "../../../../redux/modules/archives";
import {
  openSchedule,
  closeSchedule,
} from "../../../../redux/modules/content/schedule";

/**
 * @author  Conan Lai
 * @description takes in height/width prop from Icons component, which calculates size based
 * on number of icons
 * this only appears on mobile, so uses different redux actions
 */

class ArchivesIcon extends React.Component {
  render() {
    return (
      <div
        className={styles.element + " ignore-react-onclickoutside"}
        style={{
          height: this.props.height ? `${this.props.height}%` : null,
          width: this.props.width ? `${this.props.width}%` : null,
        }}
        onClick={(e) => {
          if (!this.props.isArchiveOpen) {
            this.props.openArchive();
          } else {
            this.props.closeArchive();
          }
          if (this.props.isScheduleOpen) {
            this.props.closeSchedule();
          }
        }}
      >
        <div className={styles.imgwrapper}>
          <img src={icon} />
        </div>
      </div>
    );
  }
}

ArchivesIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  openArchive: PropTypes.func,
  closeArchive: PropTypes.func,
  isArchiveOpen: PropTypes.bool,
  openSchedule: PropTypes.func,
  closeSchedule: PropTypes.func,
};

const mapPropsToState = (state) => {
  return {
    z: state._shop.z,
    isArchiveOpen: state._archives.open,
    isScheduleOpen: state._schedule.open,
  };
};

const ConnectedArchivesIcon = connect(mapPropsToState, {
  openArchive,
  closeArchive,
  openSchedule,
  closeSchedule,
})(ArchivesIcon);

export default ConnectedArchivesIcon;
