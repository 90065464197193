import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./SideBar.module.css";

import { closeAll } from "../../redux/modules/ui";

/**
 * @author  Conan Lai
 * @description this component uses React.Children to count number of children
 * and pass along a prop into each child to determine its height as a function of
 * the # of children when on desktop (or width on mobile portrait mode)
 */

class Icons extends React.Component {
  render() {
    let height;
    let width;
    if (this.props.foundWindowSize) {
      const size = 100 / React.Children.count(this.props.children);
      if (this.props.isMobile) {
        if (this.props.mobileOrientation === "landscape") {
          height = size;
        } else {
          width = size;
        }
      } else {
        height = size;
      }
    }
    const withSize = React.Children.map(this.props.children, (child) => {
      if (child !== null) {
        return React.cloneElement(child, {
          height,
          width,
          isMobile: this.props.isMobile,
          closeAll: this.props.closeAll,
        });
      }
    });
    return <div className={styles.icons}>{withSize}</div>;
  }
}

Icons.propTypes = {
  isMobile: PropTypes.bool,
  foundWindowSize: PropTypes.bool,
  mobileOrientation: PropTypes.string,
  closeAll: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    foundWindowSize: state._ui.foundWindowSize,
    isMobile: state._ui.isMobile,
    mobileOrientation: state._ui.mobileOrientation,
  };
};

const ConnectedIcons = connect(mapStateToProps, { closeAll })(Icons);

export default ConnectedIcons;
