import React from 'react';
import ImageUploader from '../../EditorUI/ImageUploader/ImageUploader';
import styles from '../AdminEditor.module.css';

import config from '../../../../config';

class Background extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			url: '',
			thumbnail: '',
			tiled: false,
		};
		this.handleNewImage = this.handleNewImage.bind(this);
		this.handleTiled = this.handleTiled.bind(this);
		this.handleUndo = this.handleUndo.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.mounted = true;
		this.updateData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.url !== this.props.url) {
			this.updateData();
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	handleNewImage(url) {
		const splits = url.split('/');
		splits[6] = 'c_thumb,w_50,h_50';
		this.setState({
			url,
			thumbnail: splits.join('/'),
		});
	}

	handleTiled(event) {
		this.setState({
			tiled: !this.state.tiled,
		});
	}

	handleUndo() {
		this.updateData();
	}

	handleSubmit() {
		const payload = {
			thumbnail: this.state.thumbnail,
			url: this.state.url,
			tiled: this.state.tiled,
		};

		const payloadJSON = JSON.stringify(payload);
		fetch(`${config.api_path}/backgrounds/${this.props._id}`, {
			method: 'put',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
			body: payloadJSON,
		})
			.then((response) => {
				if (response.status === 401) {
					this.props.signOut();
				} else {
					return response.json();
				}
			})
			.then((json) => {
				console.log(json);
				this.props.updateData();
			})
			.catch((error) => {
				console.log('Request failed', error);
			});
	}

	updateData() {
		const { url, thumbnail, tiled } = this.props;
		this.setState({
			url,
			thumbnail,
			tiled,
		});
	}

	render() {
		const deletebutton =
			this.props.url !== this.state.url ? (
				<div>
					<button className={styles.button} onClick={this.handleUndo}>
						undo change
					</button>
				</div>
			) : null;
		return (
			<div>
				<h1>{this.props.num}</h1>
				<div className={styles.section}>
					<span className={styles.label}>main image preview: </span>
					{this.state.url}
					<br />
					<img className={styles.image} alt={this.state.url} src={this.state.url} />
					<br />
					upload new main image:{' '}
					<ImageUploader preset="n10as_backgrounds" handleNewImage={this.handleNewImage} />
				</div>
				<div className={styles.section}>
					<br />
					<input name="tiled" type="checkbox" checked={this.state.tiled} onChange={this.handleTiled} />
					Check this box if background is to be tiled and repeated
				</div>
				<div className={styles.section}>
					{deletebutton}
					<div>
						<button className={styles.button} onClick={this.handleSubmit}>
							save this
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default Background;
