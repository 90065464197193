import React from 'react';
import Select from 'react-select';
import LinksEdit from '../../EditorUI/LinksEdit/LinksEdit';
import ImageUploader from '../../EditorUI/ImageUploader/ImageUploader';
import styles from '../AdminEditor.module.css';
import config from '../../../../config';

const initialState = {
	name: '',
	image: '',
	slug: '',
	description: '',
	timeslot: '',
	links: [],
	id: '',
	tags: [],
	error: '',
};

class EditShow extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.handleName = this.handleName.bind(this);
		this.handleSlug = this.handleSlug.bind(this);
		this.handleDesc = this.handleDesc.bind(this);
		this.handleTags = this.handleTags.bind(this);
		this.handleTimeslot = this.handleTimeslot.bind(this);
		this.handleAddLink = this.handleAddLink.bind(this);
		this.handleLinksURL = this.handleLinksURL.bind(this);
		this.handleLinksDesc = this.handleLinksDesc.bind(this);
		this.handleNewImage = this.handleNewImage.bind(this);
		this.handleDeleteImage = this.handleDeleteImage.bind(this);
		this.updateData = this.updateData.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.validateInputs = this.validateInputs.bind(this);
	}

	componentDidMount() {
		this.mounted = true;
		if (this.props.editMode === 'edit') {
			this.updateData();
		}
	}

	// we use this pattern to refetch data if user selects a different one to edit
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedShow !== this.props.selectedShow) {
			this.updateData();
		}
		if (prevProps.editMode === 'edit' && this.props.editMode === 'add') {
			this.setState(initialState);
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	handleName(event) {
		this.setState({
			name: event.target.value,
		});
	}

	handleSlug(event) {
		this.setState({
			slug: event.target.value,
		});
	}

	handleDesc(event) {
		this.setState({
			description: event.target.value,
		});
	}

	handleTags(vals) {
		this.setState({
			tags: vals,
		});
	}

	handleTimeslot(event) {
		this.setState({
			timeslot: event.target.value,
		});
	}

	handleAddLink() {
		const state = this.state.links;
		state.push({ link: '', name: '' });
		this.setState({ links: state });
	}

	handleLinksURL(i, event) {
		const state = this.state.links;
		state[i].link = event.target.value;
		this.setState({ links: state });
	}

	handleLinksDesc(i, event) {
		const state = this.state.links;
		state[i].name = event.target.value;
		this.setState({ links: state });
	}

	handleNewImage(url) {
		this.setState({ image: url });
	}

	handleDeleteImage() {
		this.setState({ image: '' });
	}

	handleDelete() {
		fetch(`${config.api_path}/shows/${this.state.id}`, {
			method: 'delete',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				if (response.status === 401) {
					this.props.signOut();
				} else {
					return response.json();
				}
			})
			.then((json) => {
				this.props.handleRefresh();
			})
			.catch((error) => {
				console.log('Request failed', error);
			});
	}

	handleSubmit() {
		const { valid, err, } = this.validateInputs();
    if (!valid) {
      this.setState({
        error: err,
      });
    } else {
			const editMode = this.props.editMode;
			const url = editMode === 'edit' ? `${config.api_path}/shows/${this.state.id}` : `${config.api_path}/shows`;
			const fetchMode = editMode === 'edit' ? 'put' : 'post';
			const payload = {
				name: this.state.name,
				image: this.state.image,
				slug: this.state.slug,
				description: this.state.description,
				timeslot: this.state.timeslot,
				links: this.state.links,
				tags: this.state.tags,
			};
			const payloadJSON = JSON.stringify(payload);
			fetch(url, {
				method: fetchMode,
				headers: {
					jwt: localStorage.getItem('user_token'),
					'Content-Type': 'application/json',
				},
				body: payloadJSON,
			})
				.then((response) => {
					if (response.status === 401) {
						this.props.signOut();
					} else {
						return response.json();
					}
				})
				.then((json) => {
					this.props.handleRefresh();
				})
				.catch((error) => {
					this.setState({
						error,
					});
				});
		}
	}

	updateData() {
		fetch(`${config.api_path}/shows/${this.props.selectedShow}`)
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				// this line is here because the server returns an array even if there is only
				// one show with given slug
				const showinfo = json;
				if (this.mounted === true) {
					this.setState({
						name: showinfo.name,
						image: showinfo.image,
						slug: showinfo.slug,
						description: showinfo.description,
						timeslot: showinfo.timeslot,
						links: showinfo.links,
						id: showinfo._id,
						tags: showinfo.tags,
					});
				}
			});
	}

	validateInputs() {
    let valid = true;
    let err = null;
    const payload = {
      name: this.state.name,
      image: this.state.image,
      slug: this.state.slug,
      description: this.state.description,
      access_token: localStorage.token,
    };
    Object.keys(payload).some((key) => {
      if (payload[key] === '' || payload[key] === []) {
        valid = false;
        err = 'incomplete inputs';
      }
    });
    if (this.state.slug && this.state.slug.includes('mixcloud.com')) {
			valid = false;
      err = 'slug must not be full URL - only use "a-cutie-who-hates-blu-ray" and not www.mixcloud.com/n10as/playlists/a-cutie-who-hates-blu-ray';
    }
    if (valid) {
      return {
        payload,
        valid: true,
        err,
      };
    }
    return {
      valid: false,
      payload: null,
      err,
    };
  }

	render() {
		const tagoptions = [
			{ value: 'groove', label: 'groove' },
			{ value: 'dance', label: 'dance' },
			{ value: 'house', label: 'house' },
			{ value: 'disco', label: 'disco' },
			{ value: 'electronic', label: 'electronic' },
			{ value: 'experimental', label: 'experimental' },
			{ value: 'techno', label: 'techno' },
			{ value: 'reggae/dub', label: 'reggae/dub' },
			{ value: 'indie', label: 'indie' },
			{ value: 'soul', label: 'soul' },
			{ value: 'classical', label: 'classical' },
			{ value: 'pop', label: 'pop' },
			{ value: 'world', label: 'world' },
			{ value: 'jazz', label: 'jazz' },
			{ value: 'blues', label: 'blues' },
			{ value: 'rnb', label: 'rnb' },
			{ value: 'ambient', label: 'ambient' },
			{ value: 'country', label: 'country' },
			{ value: 'rock', label: 'rock' },
			{ value: 'heavy', label: 'heavy' },
			{ value: 'post-punk', label: 'post-punk' },
			{ value: 'psych', label: 'psych' },
			{ value: 'specials', label: 'specials' },
			{ value: 'hiphop/rap', label: 'hiphop/rap' },
			{ value: 'talk/discussion', label: 'talk/discussion' },
			{ value: 'lifestyle', label: 'lifestyle' },
			{ value: 'other', label: 'other' },
		];
		const deletebutton =
			this.props.editMode === 'edit' ? (
				<div>
					<button className={styles.button} onClick={this.handleDelete}>
						delete this
					</button>
				</div>
			) : null;
		const deleteimagebutton =
			this.state.image !== '' ? (
				<div>
					<button className={styles.button} onClick={this.handleDeleteImage}>
						delete image
					</button>
				</div>
			) : null;
		const error = this.state.error !== '' ? <div className={styles.error}>{this.state.error}</div> : null;
		return (
			<div>
				<div className={styles.section}>
					<span className={styles.label}>name of show: </span>
					<input value={this.state.name} onChange={this.handleName} className={styles.input} />
				</div>
				<div className={styles.section}>
					<span className={styles.label}>slug: </span>
					{
						"this is the text that appears in the URL - if creating a new show, please create a mixcloud playlist first, and copy the URL parameter from there, so that the show bio will be able to pick up the archives. this should be the show name with spaces and other special characters replaced with dashes, for example 'janets-choice'"
					}
					<input value={this.state.slug} onChange={this.handleSlug} className={styles.input} />
				</div>
				<div className={styles.section}>
					<span className={styles.label}>description: </span>
					<textarea
						rows="10"
						value={this.state.description}
						onChange={this.handleDesc}
						className={styles.input}
					/>
				</div>
				<div className={styles.section}>
					<span className={styles.label}>tags: </span>
					<Select
						name="tags"
						value={this.state.tags}
						options={tagoptions}
						onChange={this.handleTags}
						multi
						searchable
					/>
				</div>
				<div className={styles.section}>
					<span className={styles.label}>timeslot: </span>
					<input
						value={this.state.timeslot}
						onChange={this.handleTimeslot}
						className={styles.input}
						placeholder="4PM / FRIDAYS/ BIWEEKLY"
					/>
				</div>
				<div className={styles.section}>
					<span className={styles.label}>links: </span>
					<LinksEdit
						handleAdd={this.handleAddLink}
						handleUrl={this.handleLinksURL}
						handleDesc={this.handleLinksDesc}
						links={this.state.links}
					/>
				</div>
				<div className={styles.section}>
					<span className={styles.label}>image preview: </span>
					{this.state.image}
					<br />
					<img className={styles.image} alt={this.state.name} src={this.state.image} />
					<br />
					{deleteimagebutton}
					upload new: <ImageUploader preset="n10as_shopandshow" handleNewImage={this.handleNewImage} />
				</div>
				<div className={styles.section}>
					{deletebutton}
					{error}
					<div>
						<button className={styles.button} onClick={this.handleSubmit}>
							submit this
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default EditShow;
