import React from "react";
import Marquee from "../../lib/Marquee";
import Event from "./Event.js";
import styles from "./EventTicker.module.css";
import config from "../../../config";

function filterEvents(events) {
  const now = new Date().getTime();
  return events
    .filter((event) => {
      const isInfuture = event.endDate > now;
      return isInfuture;
    })
    .sort((a, b) => {
      return a.endDate - b.endDate;
    });
}

/**
 * @author  Conan Lai
 * @description EventTicker fetches all events from API and displays them in a Marquee
 */

class EventTicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { events: [] };
  }

  componentDidMount() {
    this.mounted = true;
    fetch(`${config.api_path}/new-events`)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (this.mounted === true) {
          this.setState({ events: filterEvents(json) });
        }
      });
  }

  render() {
    const events = this.state.events;
    return (
      <div className={styles.tickertext}>
        <Marquee>
          <ul className={styles.eventslist}>
            {events.map((event) => {
              return <Event key={event.name} {...event} />;
            })}
          </ul>
        </Marquee>
      </div>
    );
  }
}

export default EventTicker;
