import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./ArchivePlayer.module.css";

import { changeArchive } from "../../../../redux/modules/archives";

import { playArchive, pauseArchive } from "../../../../redux/modules/audio";

/**
 * @author  Conan Lai
 * @description renders a Mixcloud show widget - see their Javascript widget API documentation
 * https://www.mixcloud.com/developers/widget/
 * on Desktop, it displays on pageload and is always hidden
 * when a new archive is selected, it loads it in via the 'load' method provided by the API
 */

// TO DO: mobile + retry event listener unregistering

class ArchivePlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
    };
    this.handleArchiveBar = this.handleArchiveBar.bind(this);
  }

  // this turned out to be the cleanest solution I could find for now - we add event listener on the actual DOM object, rather than having a bunch of handlers
  // in several different React components
  componentDidUpdate(prevProps) {
    if (prevProps.selectedArchiveSlug !== this.props.selectedArchiveSlug) {
      // don't autoplay on mobile since it will bug, has to play via user click
      const autoplay = !this.props.isMobile;
      this.widget = window.Mixcloud.PlayerWidget(
        document.getElementById("archiveplayer")
      ); // eslint-disable-line no-undef, new-cap
      this.widget.ready.then(() => {
        this.pauseWidget = () => {
          this.widget.pause();
        };
        this.widget.load(this.props.selectedArchiveSlug, autoplay);
        // DOM elements with IDs are global vars in JS, so 'player' refers to the player element created in
        // the AudioPlayer component
        player.removeEventListener("play", this.pauseWidget, true); // eslint-disable-line no-undef
        player.addEventListener("play", this.pauseWidget, true); // eslint-disable-line no-undef
        this.widget.events.play.on(() => {
          this.props.playArchive();
          player.pause(); // eslint-disable-line no-undef
        });
        this.widget.events.progress.on((position, duration) => {
          const progress = (position / duration) * 100;
          if (progress) {
            this.setState({
              progress: (position / duration) * 100,
            });
          }
        });
      });
    }
    if (prevProps.source !== this.props.source) {
      if (
        prevProps.source === "archive" &&
        this.props.source === "pausedarchive"
      ) {
        this.widget.pause();
      } else if (
        prevProps.source === "pausedarchive" &&
        this.props.source === "archive"
      ) {
        this.widget.play();
      }
    }
  }

  handleArchiveBar(e) {
    const position = e.nativeEvent.offsetX;
    const width = this.node.offsetWidth;
    const percentage = position / width;
    this.widget.getDuration().then((dur) => {
      const toTrack = Number(dur * percentage);
      this.widget.seek(toTrack).then((res) => {
        // TODO: are we missing functionality here?
        // console.log(res);
      });
    });
  }

  render() {
    let progresswidth = 0;
    if (
      this.props.source === "archive" ||
      this.props.source === "pausedarchive"
    ) {
      progresswidth = (this.state.progress / 100) * this.node.offsetWidth || 1;
    }

    return (
      <div
        ref={(node) => {
          this.node = node;
        }}
        className={styles.archiveplayer}
        style={{
          display:
            this.props.source === "archive" ||
            this.props.source === "pausedarchive"
              ? "block"
              : "none",
          position: this.props.isMobile ? "static" : "absolute",
        }}
        onClick={this.handleArchiveBar}
      >
        <div
          className={styles.progress}
          style={{
            width: progresswidth,
            display:
              this.props.isMobile &&
              (this.props.source === "archive" ||
                this.props.source === "pausedarchive")
                ? "none"
                : "block",
          }}
        />
        <iframe
          style={{
            display:
              this.props.isMobile &&
              (this.props.source === "archive" ||
                this.props.source === "pausedarchive")
                ? "block"
                : "none",
          }}
          id="archiveplayer"
          width="425px"
          height="60"
          src="https://www.mixcloud.com/widget/iframe/?feed=https://www.mixcloud.com/n10as/11_12_collect-callmp3/&hide_cover=1&mini=1&light=0&autoplay=0"
          frameBorder="0"
          scrolling="no"
        />
      </div>
    );
  }
}

ArchivePlayer.propTypes = {
  source: PropTypes.string,
  selectedArchiveSlug: PropTypes.string,
  changeArchive: PropTypes.func,
  playArchive: PropTypes.func,
  pauseArchive: PropTypes.func,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    source: state._audio.source,
    selectedArchiveSlug: state._archives.selectedArchiveSlug,
    isMobile: state._ui.isMobile,
  };
};

const ConnectedArchivePlayer = connect(mapStateToProps, {
  changeArchive,
  playArchive,
  pauseArchive,
})(ArchivePlayer);

export default ConnectedArchivePlayer;
