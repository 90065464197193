import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import OpacitySlider from "./OpacitySlider";
import styles from "./EasterEggs.module.css";

import opacity from "./opacity.png";
import openall from "./openall.png";
import closeall from "./closeall.png";

import { changeBackground } from "../../../redux/modules/background";

import { bringBulletinBoardToFront } from "../../../redux/modules/content/bulletinboard";
import { bringChatToFront } from "../../../redux/modules/content/chat";
import { bringInstagramToFront } from "../../../redux/modules/content/instagram";
import { bringShopToFront } from "../../../redux/modules/content/shop";
import { bringShowsToFront } from "../../../redux/modules/content/shows";

import { incrementTopZ, closeAll } from "../../../redux/modules/ui";

/**
 * @author  Conan Lai
 * @description bonus features for top bar on desktop
 */

class EasterEggs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loadedWindows: false,
      opacityOpen: false,
    };
    this.toggleAll = this.toggleAll.bind(this);
    this.toggleOpacityOpen = this.toggleOpacityOpen.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loadedWindows: true,
      });
    }, 1000);
  }

  toggleOpacityOpen() {
    this.setState({
      opacityOpen: !this.state.opacityOpen,
    });
  }

  toggleAll() {
    // if any windows open, close all, if not, open specified set
    if (this.props.open) {
      this.props.closeAll();
    } else {
      const newZ = this.props.topZIndex + 1;
      const toOpen = [
        this.props.bringBulletinBoardToFront,
        this.props.bringShopToFront,
        this.props.bringShowsToFront,
        this.props.bringChatToFront,
      ];
      for (let i = 0; i < toOpen.length; i++) {
        toOpen[i](newZ + i);
        this.props.incrementTopZ();
      }
    }
  }

  render() {
    const opacitypanel = this.state.opacityOpen ? (
      <OpacitySlider toggleOpacityOpen={this.toggleOpacityOpen} />
    ) : null;
    let togglebutton = closeall;
    if (this.state.loadedWindows) {
      togglebutton = this.props.open ? closeall : openall;
    }
    return (
      <div className={styles.eastereggs}>
        <ReactTooltip class={styles.tooltip} type="dark" place="bottom" />
        <div
          className={styles.egg + " ignore-react-onclickoutside"}
          // style={{ background: 'white', }}
          onClick={this.toggleOpacityOpen}
          data-tip="opacity"
        >
          <img src={opacity} />
        </div>
        <div
          className={styles.egg}
          // style={{ background: 'grey', }}
          onClick={this.toggleAll}
          data-tip="toggle all"
        >
          <img src={togglebutton} />
        </div>
        {opacitypanel}
      </div>
    );
  }
}

EasterEggs.propTypes = {
  open: PropTypes.bool,
  topZIndex: PropTypes.number,
  changeBackground: PropTypes.func,
  bringBulletinBoardToFront: PropTypes.func,
  bringChatToFront: PropTypes.func,
  bringInstagramToFront: PropTypes.func,
  bringShopToFront: PropTypes.func,
  bringShowsToFront: PropTypes.func,
  incrementTopZ: PropTypes.func,
  closeAll: PropTypes.func,
};

const mapPropsToState = (state) => {
  return {
    open:
      state._bulletinboard.open === "open" ||
      state._chat.open === "open" ||
      state._instagram.open === "open" ||
      state._shop.open === "open" ||
      state._shows.open === "open" ||
      state._show.open === "open" ||
      state._schedule.open === "open" ||
      state._archives.open === "open" ||
      state._about.open === "open",
    topZIndex: state._ui.topZIndex,
  };
};

const ConnectedEasterEggs = connect(mapPropsToState, {
  changeBackground,
  bringBulletinBoardToFront,
  bringChatToFront,
  bringInstagramToFront,
  bringShopToFront,
  bringShowsToFront,
  incrementTopZ,
  closeAll,
})(EasterEggs);

export default ConnectedEasterEggs;
