export default function reducer(state = {
  open: 'closed',
  z: 1,
  w: 0,
  h: 0,
  x: 0,
  y: 0,
}, action = {}) {
  switch (action.type) {
    case 'CALCULATE_BULLETIN_BOARD':
      return {
        ...state,
        x: action.x,
        y: action.y,
        w: action.w,
        h: action.h,
        open: action.open,
      };
    case 'RECALCULATE_BULLETIN_BOARD_ON_MOBILE':
      return {
        ...state,
        x: action.x,
        y: action.y,
        w: action.w,
        h: action.h,
      };
    case 'OPEN_BULLETIN_BOARD':
      return {
        ...state,
        open: 'open',
        z: action.z,
      };
    case 'CLOSE_BULLETIN_BOARD':
      return {
        ...state,
        open: 'closed',
      };
    case 'BRING_BULLETIN_BOARD_TO_FRONT':
      return {
        ...state,
        z: action.z,
        open: 'open',
      };
    default:
      return state;
  }
}

export const calculateBulletinBoard = (params) => {
  const { isMobile, windowHeight, windowWidth, landedSlug, mobileOrientation, } = params;
  if (isMobile) {
    return {
      type: 'CALCULATE_BULLETIN_BOARD',
      x: 0,
      y: 0,
      w: mobileOrientation === 'landscape' ? windowWidth - 150 : windowWidth,
      h: mobileOrientation === 'landscape' ? windowHeight : windowHeight - 150,
      open: 'closed',
    };
  }
  const width = Math.max(windowWidth / 3.75, 300);
  const height = Math.max(width * 0.75, 300);
  return {
    type: 'CALCULATE_BULLETIN_BOARD',
    x: 40,
    y: 40,
    w: width,
    h: height,
    open: (!landedSlug) ? 'open' : 'closed',
  };
};

export const recalculateBulletinBoardOnMobile = (params) => {
  const { windowWidth, windowHeight, mobileOrientation, } = params;
  return {
    type: 'RECALCULATE_BULLETIN_BOARD_ON_MOBILE',
    x: 0,
    y: 0,
    w: mobileOrientation === 'landscape' ? windowWidth - 150 : windowWidth,
    h: mobileOrientation === 'landscape' ? windowHeight : windowHeight - 150,
  };
};

export const openBulletinBoard = (z) => {
  return {
    type: 'OPEN_BULLETIN_BOARD',
    z,
  };
};

export const closeBulletinBoard = (e) => {
  // stop propagation here to prevent handleBringToFront from getting called
  if (e && e.target) {
    e.stopPropagation();
  }
  return {
    type: 'CLOSE_BULLETIN_BOARD',
  };
};

export const bringBulletinBoardToFront = (z) => {
  return {
    type: 'BRING_BULLETIN_BOARD_TO_FRONT',
    z,
  };
};
