import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import config from '../../../../config';
import './style.css';

const initalErrorValues = {
	isError: false,
	error: '',
};

function formatDate(date) {
	return moment(date).format('YYYY/MM/DD HH:mm Z');
}

function formatBroadcastDate(date) {
	return date.toString().split('T')[0];
}

function UploadMixStatus({ authenticated, general, userType, associatedShow }) {
	const [isLoading, setIsLoading] = useState(false);

	const [error, setError] = useState(initalErrorValues);
	const [uploads, setUploads] = useState([]);

	const isAdmin = userType === 'admin';

	async function handleRetry(id) {
		const url = `${config.api_path}/uploads/${id}/retry`;
		const response = await fetch(url, {
			method: 'PUT',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
		});
		console.log(response);
	}

	async function handleMarkAirtimeIncomplete(id, index) {
		const upload = uploads[index];
		if (!(upload.airtimeCompletedProcessingAt)) {
			return;
		}
		const url = `${config.api_path}/uploads/${id}/mark-airtime-incomplete`;
		const response = await fetch(url, {
			method: 'PUT',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
		});
	}

	async function fetchUploads() {
		try {
			setIsLoading(true);
			const urlModifier = !isAdmin && associatedShow ? `/${associatedShow}` : '';
			const url = `${config.api_path}/uploads${urlModifier}`;
			const response = await fetch(url, {
				method: 'GET',
				mode: 'cors',
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					jwt: localStorage.getItem('user_token'),
					'Content-Type': 'application/json',
				},
			});
			switch (response.status) {
				case 200:
					const json = await response.json();
					setUploads(json.reverse());
					setIsLoading(false);
					break;
				case 401:
					setIsLoading(false);
					setError({
						isError: true,
						error: 'user is not authenticated',
					});
					break;
				default:
					setIsLoading(false);
					setError({
						isError: true,
						error: 'Unknown error',
					});
			}
		} catch (err) {
			setIsLoading(false);
			setError({
				isError: true,
				error: 'Unknown error',
			});
		}
	}
	useEffect(() => {
		fetchUploads();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoading) return null;

	if (error.isError)
		return (
			<div>
				<span>{error.error}</span>
			</div>
		);

	return (
		<div className="uploads-queue-body">
			<button onClick={() => fetchUploads()}>Refresh Queues</button>
			<br />
			<Link to="/admin">Back To Admin panel</Link>
			<br />
			<br />
			<h2>How to read this table</h2>
			<br />
			The status column shows the upload status of the episode.
			<br />
			A <span className="green-text">green</span> circle indicates
			a successful upload to both mixcloud and airtime.
			<br />
			A <span className="yellow-text">yellow</span> circle indicates
			that at least one of the two uploads is still pending.
			<br />
			A <span className="red-text">red</span> circle indicates
			that at least one of the two uploads has failed <b>or</b> that the google drive file is invalid. Look at the other columns
			for more information in that case.
			<br />
			<br />
			In the case of a <b>G-Drive File Error</b>, the file is either corrupt, not publicly shared, not an mp3, not greater than 350mb.
			<br />
			You can re-upload the file to google drive and use our upload form again to create another upload.
			<br />
			<br />
			The <b>retry attempts</b> column tracks how many times an upload is attempted. The system is built to handle failures
			<br />
			and retry uploads at a later time (for example, if airtime or mixcloud is down). After 5, it is considered failed.
			<br />
			If one of the services has completely failed but the other has succeeded, please notify us as we may have to manually upload in that case.
			<br />
			<br />
			Files will be uploaded to Airtime and Mixcloud in the following name format: <i>Work in Progress-2020-11-18.mp3</i>
			{isAdmin &&
				<div>
					<br/>
					<br/>
					The retry button should only be used to certain cases such as debugging. Otherwise, the server will already automatically retry uploads at certain time intervals. So this button should only be used in very specific use cases (if you're wondering if you need to use it, you probably don't)
					<br/>
					<br/>
					The 'mark as airtime not completed' button can be used to solve for cases where the file was considered uploaded successfully, but does not show up in Airtime after a while. This
					is most likely because the Airtime space usage was over the limit - Airtime still lets us successfully upload the file, but it doesn't actually appear. Marking this as incomplete
					after we've deleted older files will queue it up to be re-uploaded at a later time.
					<br/>
					<br/>
					The current retry period is 10 minutes.
				</div>
			}
			<br />
			<br />
			<table>
				<thead>
					<tr>
						<th>Status</th>
						<th>
							Associated <br /> Show
						</th>
						<th>
							Broadcast
							<br />
							Date
						</th>
						<th>
							<div>Started</div>
							Processing At
						</th>
						<th>G-Drive URL</th>
						<th>G-Drive File Error</th>
						<th>Retry Atempts</th>
						<th>
							<div>
								Mixcloud
								<br />
								Completed at
							</div>
						</th>
						<th>
							Airtime<br />Completed at
						</th>
						<th>
							Mixcloud<br />Next available retry time
						</th>
						{
							isAdmin &&
							<React.Fragment>
								<th>
									Retry
								</th>
								<th>
									Mark as Airtime incomplete
								</th>
							</React.Fragment>
						}
					</tr>
				</thead>
				<tbody>
					{uploads.map((item, index) => {
						const {
							_id,
							associatedShow,
							airtimeCompletedProcessingAt,
							mixcloudCompletedProcessingAt,
							mixcloudNumberOfRetryAttempts,
							airtimeNumberOfRetryAttempts,
							broadcastDate,
							startedProcessingAt,
							mixcloudRateLimitingNextProcessingTime,
							googleDrivePrivacyError,
							googleDriveInvalidError,
						} = item;
						return (
							<tr key={`${index}-upload-queue`}>
								<td>
									<ColorStatusPicker
										airtimeCompletedProcessingAt={airtimeCompletedProcessingAt}
										mixcloudCompletedProcessingAt={mixcloudCompletedProcessingAt}
										googleDrivePrivacyError={googleDrivePrivacyError}
										googleDriveInvalidError={googleDriveInvalidError}
										mixcloudNumberOfRetryAttempts={mixcloudNumberOfRetryAttempts}
										airtimeNumberOfRetryAttempts={airtimeNumberOfRetryAttempts}
									/>
								</td>
								<td>{associatedShow}</td>
								<td>{formatBroadcastDate(broadcastDate)}</td>
								<td>{formatDate(startedProcessingAt)}</td>
								<td>
									<a href={item.link}>Click here</a>
								</td>
								<td>
									{googleDrivePrivacyError || googleDriveInvalidError ? <span className="red-text">true</span> : ''}
								</td>
								<td>
									<span>Mixcloud:
										{renderRetryAttempts(mixcloudNumberOfRetryAttempts)}
									</span>
									<br />
									<span>Airtime:
										{renderRetryAttempts(airtimeNumberOfRetryAttempts)}
									</span>
								</td>
								<td>{mixcloudCompletedProcessingAt ? formatDate(mixcloudCompletedProcessingAt) : 'pending'}</td>
								<td>{airtimeCompletedProcessingAt ? formatDate(airtimeCompletedProcessingAt) : 'pending'}</td>
								<td>
									{mixcloudRateLimitingNextProcessingTime ? formatDate(mixcloudRateLimitingNextProcessingTime) : ''}
								</td>
								{isAdmin &&
								<React.Fragment>
									<td>
										<button onClick={() => handleRetry(_id)}>Retry</button>
									</td>
									<td>
										<button onClick={() => handleMarkAirtimeIncomplete(_id, index)}>Mark Airtime as incomplete</button>
									</td>
								</React.Fragment>
								}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}
const mapStateToProps = (state) => {
	return {
		authenticated: state._user.authenticated,
		userType: state._user.userType,
		associatedShow: state._user.associatedShow,
	};
};

const ConnectedUploadMixStatus = connect(mapStateToProps, {})(withRouter(UploadMixStatus));

export default ConnectedUploadMixStatus;

function renderRetryAttempts(retryAttempts) {
	if (!retryAttempts) {
		return <span>0</span>;
	}
	if (retryAttempts >= 5) {
		return <span className="red-text">failed</span>;
	}
	return <span>{retryAttempts}</span>
}

function ColorStatusPicker({
	airtimeCompletedProcessingAt,
	mixcloudCompletedProcessingAt,
	googleDrivePrivacyError,
	googleDriveInvalidError,
	airtimeNumberOfRetryAttempts,
	mixcloudNumberOfRetryAttempts,
}) {
	if (airtimeCompletedProcessingAt && mixcloudCompletedProcessingAt) {
		return <div className="circle green" />;
	}
	if (googleDrivePrivacyError || googleDriveInvalidError) {
		return <div className="circle red" />;
	}
	if (airtimeNumberOfRetryAttempts >= 5) {
		return <div className="circle red" />;
	}
	if (mixcloudNumberOfRetryAttempts >= 5) {
		return <div className="circle red" />;
	}
	if (airtimeCompletedProcessingAt && !mixcloudCompletedProcessingAt) {
		return <div className="circle yellow" />;
	}

	if (!airtimeCompletedProcessingAt && mixcloudCompletedProcessingAt) {
		return <div className="circle yellow" />;
	}

	return <div className="circle yellow" />;
}
