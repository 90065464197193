import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LogoHolder from "./LogoHolder/LogoHolder";
import AudioPlayer from "./AudioPlayer/AudioPlayer";
import NowPlayingContainer from "./NowPlayingContainer/NowPlaying/NowPlaying";
import styles from "./TopBar.module.css";

import ArchivesAndSchedule from "./ArchivesAndSchedule/ArchivesAndSchedule";
import BackgroundPicker from "./BackgroundPicker/BackgroundPicker";
import EasterEggs from "./EasterEggs/EasterEggs";
import HelpButton from "./HelpButton/HelpButton";

/**
 * @author  Conan Lai
 * @description top bar, makes heavy use of async component because components rendered
 * differ on desktop and mobile
 */

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.renderDesktop = this.renderDesktop.bind(this);
    this.renderMobile = this.renderMobile.bind(this);
  }

  renderDesktop() {
    return (
      <div className={styles.topbar}>
        <LogoHolder />
        <div className={styles.topleft}>
          <AudioPlayer />
          <NowPlayingContainer />
        </div>
        <div className={styles.desktop}>
          <ArchivesAndSchedule />
          <EasterEggs />
          <BackgroundPicker />
          <HelpButton />
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div className={styles.topbar}>
        <div className={styles.topleft}>
          <AudioPlayer />
          <NowPlayingContainer />
        </div>
      </div>
    );
  }

  render() {
    let content = <div className={styles.topbar} />;
    // only render once screen size determined
    if (this.props.foundWindowSize) {
      if (this.props.isMobile !== true) {
        content = this.renderDesktop();
      } else {
        content = this.renderMobile();
      }
    }
    return content;
  }
}

TopBar.propTypes = {
  isMobile: PropTypes.bool,
  foundWindowSize: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    foundWindowSize: state._ui.foundWindowSize,
    isMobile: state._ui.isMobile,
  };
};

const ConnectedTopBar = connect(mapStateToProps)(TopBar);

export default ConnectedTopBar;
