import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import debounce from "javascript-debounce";

import moment from "moment-timezone";

import styles from "../../App.module.css";

import { withRouter } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import TopBar from "../../components/TopBar/TopBar";
import SideBar from "../../components/SideBar/SideBar";
import DeskTop from "../../components/DeskTop/DeskTop";
import Background from "../../components/Background/Background";

import {
  findWindowProperties,
  resizeWindow,
  detectMobileOrientation,
  changeMobileOrientation,
  changeTimeZone,
} from "../../redux/modules/ui";
import { fetchAirtimeInfo } from "../../redux/modules/airtime";
import { fetchShows } from "../../redux/modules/content/shows";
import { fetchBackgrounds } from "../../redux/modules/background";

class Home extends React.Component {
  componentDidMount() {
    const timezone = moment.tz.guess() || "America/New_York";
    moment.tz.setDefault(timezone);
    this.props.changeTimeZone(timezone);
    const path = this.props.location.pathname;
    let slug;
    if (path.slice(1, 6) === "shows") {
      slug = path.slice(7);
    }
    if (!slug) {
      slug = "";
    }
    this.props.findWindowProperties(slug);
    this.props.fetchAirtimeInfo();
    this.props.fetchShows();
    this.props.fetchBackgrounds();

    if (process.env.NODE_ENV === "production") {
      setInterval(this.props.fetchAirtimeInfo, 10000);
    }

    if ("addEventListener" in window) {
      const handleResize = debounce(() => {
        const height = window.innerHeight;
        const width = window.innerWidth;
        this.props.resizeWindow(height, width);
        if (this.props.isMobile) {
          this.props.changeMobileOrientation(
            detectMobileOrientation(height, width)
          );
        }
      }, 100);

      window.addEventListener("resize", handleResize);
    }
  }

  // HELPERS /////////////////////

  detectMobileOrientation(height, width) {
    if (width > height) {
      return "landscape";
    }
    return "portrait";
  }

  render() {
    return (
      <div className={styles.wrap}>
        <TopBar />
        <SideBar />
        <DeskTop />
        <Background />
        <Footer />
      </div>
    );
  }
}

Home.propTypes = {
  findWindowProperties: PropTypes.func,
  fetchAirtimeInfo: PropTypes.func,
  fetchShows: PropTypes.func,
  resizeWindow: PropTypes.func,
  changeMobileOrientation: PropTypes.func,
  changeTimeZone: PropTypes.func,
  fetchBackgrounds: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    isMobile: state._ui.isMobile,
  };
};

const connectedApp = connect(mapStateToProps, {
  findWindowProperties,
  fetchShows,
  fetchAirtimeInfo,
  resizeWindow,
  changeMobileOrientation,
  changeTimeZone,
  fetchBackgrounds,
})(withRouter(Home));

export default connectedApp;
