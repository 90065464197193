import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

/**
 * @author  Conan Lai
 * @description Used for all our ticker texts, adapted from
 * npm module 'react-marquee'
 * https://github.com/jasonslyvia/react-marquee/blob/master/src/index.js
 */

const FPS = 30;
const STEP = 2;
const TIMEOUT = (1 / FPS) * 1000;

class Marquee extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      animatedWidth: 0,
      animatedWidth2: 0,
      animatedWidth3: 0,
      animatedWidth4: 0,
      overflowWidth: 0,
      containerWidth: 0,
      textWidth: 0,
    };
    this._startAnimation = this._startAnimation.bind(this);
    this._measureText = this._measureText.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  componentDidMount() {
    this._measureText();
    if (this.state.overflowWidth > 0) {
      this._startAnimation();
    }
  }

  componentDidUpdate() {
    this._measureText();
    this._startAnimation();
  }

  componentWillUnmount() {
    clearTimeout(this._marqueeTimer);
  }

  handleMouseEnter() {
    clearTimeout(this._marqueeTimer);
  }

  handleMouseLeave() {
    this._startAnimation();
  }

  _startAnimation() {
    clearTimeout(this._marqueeTimer);
    const isLeading = this.state.animatedWidth === 0;
    const timeout = isLeading ? this.props.leading : TIMEOUT;

    const animate = () => {
      const { containerWidth, textWidth } = this.state;
      let animatedWidth = this.state.animatedWidth - STEP;
      let animatedWidth2 = this.state.animatedWidth2 - STEP;
      let animatedWidth3 = this.state.animatedWidth3 - STEP;

      if (0 - animatedWidth > textWidth) {
        animatedWidth = 0;
      }
      if (0 - animatedWidth2 > textWidth) {
        animatedWidth2 = 0;
      }
      if (0 - animatedWidth3 > textWidth) {
        animatedWidth3 = 0;
      }
      this.setState({
        animatedWidth,
        animatedWidth2,
        animatedWidth3,
      });
    };

    this._marqueeTimer = setTimeout(animate, timeout);
  }

  _measureText() {
    const container = ReactDOM.findDOMNode(this);
    const node = ReactDOM.findDOMNode(this.refs.text);

    if (container && node) {
      const containerWidth = container.offsetWidth;
      const textWidth = node.offsetWidth;
      const overflowWidth = textWidth - containerWidth;

      if (overflowWidth !== this.state.overflowWidth) {
        this.setState({
          overflowWidth,
          containerWidth,
          textWidth,
        });
      }
    }
  }

  render() {
    const content = (
      <div
        style={{
          display: "inline-block",
          whiteSpace: "nowrap",
        }}
      >
        <div
          style={{
            display: "inline-block",
            paddingRight: "100px",
            whiteSpace: "nowrap",
          }}
        >
          {this.props.children}
        </div>
        <div
          style={{
            display: "inline-block",
            paddingRight: "100px",
            whiteSpace: "nowrap",
          }}
        >
          {this.props.children}
        </div>
        <div
          style={{
            display: "inline-block",
            paddingRight: "100px",
            whiteSpace: "nowrap",
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
    const style = this.props.nowplaying
      ? { display: "table", position: "absolute", top: 0, left: 0 }
      : { display: "table" };
    return (
      <div
        style={style}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div
          ref="text"
          style={{
            transform: `translate3d(${this.state.animatedWidth}px, 0px, 0px)`,
            webkitTransform: `translate3d(${this.state.animatedWidth}px, 0px, 0px)`,
            MozTransform: `translate3d(${this.state.animatedWidth}px, 0px, 0px)`,
            display: "table-cell",
          }}
        >
          {content}
        </div>
        <div
          ref="text2"
          style={{
            transform: `translate3d(${this.state.animatedWidth}px, 0px, 0px)`,
            webkitTransform: `translate3d(${this.state.animatedWidth}px, 0px, 0px)`,
            MozTransform: `translate3d(${this.state.animatedWidth}px, 0px, 0px)`,
            display: "table-cell",
          }}
        >
          {content}
        </div>
        <div
          ref="text3"
          style={{
            transform: `translate3d(${this.state.animatedWidth}px, 0px, 0px)`,
            webkitTransform: `translate3d(${this.state.animatedWidth}px, 0px, 0px)`,
            MozTransform: `translate3d(${this.state.animatedWidth}px, 0px, 0px)`,
            display: "table-cell",
          }}
        >
          {content}
        </div>
      </div>
    );
  }
}

Marquee.propTypes = {
  text: PropTypes.string,
  hoverToStop: PropTypes.bool,
  nowplaying: PropTypes.bool,
  loop: PropTypes.bool,
  leading: PropTypes.number,
  trailing: PropTypes.number,
  className: PropTypes.string,
};

Marquee.defaultProps = {
  text: "",
  hoverToStop: false,
  loop: false,
  leading: 0,
  trailing: 0,
};

export default Marquee;
