import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./LogoHolder.module.css";

import logo from "./logo.gif";
import backlive from "./icons_backlive.png";

import { playLive } from "../../../redux/modules/audio";

/**
 * @author  Francis Goodship & Conan Lai
 * @description only renders on desktop, logo holder, displays a 'back to live' button
 * while user is listening to archives
 */

class LogoHolder extends React.Component {
  render() {
    const content =
      this.props.source === "pausedarchive" ||
      this.props.source === "archive" ? (
        <img
          onClick={this.props.playLive}
          className={styles.logo}
          alt="logo"
          src={backlive}
        />
      ) : (
        <img className={styles.logo} alt="logo" src={logo} />
      );
    return <div className={styles.logoholder}>{content}</div>;
  }
}

LogoHolder.propTypes = {
  source: PropTypes.string,
  playLive: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    source: state._audio.source,
  };
};

const ConnectedLogoHolder = connect(mapStateToProps, { playLive })(LogoHolder);

export default ConnectedLogoHolder;
