import React, { useState, useEffect } from 'react';
import config from '../../../../config';
import { connect } from 'react-redux';

import DateTime from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from '../AdminEditor.module.css';

function UserUploadGoogleDrive({ signOut, handleRefresh, shows, _id, userType }) {
	const isAdmin = userType === 'admin';
	const [loading, setLoading] = useState(true);
	const [isCustom, setIsCustom] = useState(false);
	const [doesUserHaveAssociatedShow, setDoesUserHaveAssociatedShow] = useState(false);
	const [showDate, setShowDate] = useState('');
	const [link, setLink] = useState('');
	const [showName, setShowName] = useState('');
	const [customShowName, setCustomShowName] = useState('');
	const [isValidated, setIsValidated] = useState(false);
	const [error, setError] = useState({
		isError: false,
		errorMsg: '',
	});
	const [isCompleted, setIsCompleted] = useState(false);

	async function apiUploadShow(body, e) {
		e.preventDefault();
		try {
			const url = `${config.api_path}/uploads/`;
			const response = await fetch(url, {
				method: 'POST',
				mode: 'cors',
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					jwt: localStorage.getItem('user_token'),
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(body),
			});

			switch (response.status) {
				case 200:
					setError({
						errorMsg: '',
						isError: false,
					});
					setIsCompleted(true);
					break;
				case 401:
					setError({
						errorMsg: 'Not authenticated',
						isError: true,
					});
					setIsCompleted(false);
					signOut();
					break;
				default:
					setError({
						errorMsg: 'An unkown error occured',
						isError: true,
					});
					setIsCompleted(false);
			}
		} catch (err) {
			setError({
				errorMsg: err,
				isError: true,
			});
			setIsCompleted(false);
		}
	}

	async function getUser(unmounted = false) {
		const url = `${config.api_path}/users/${_id}`;
		const response = await fetch(url, {
			method: 'GET',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
		});
		const body = await response.json();
		const { associatedShow } = body[0];
		if (associatedShow) {
			setShowName(associatedShow);
			setDoesUserHaveAssociatedShow(true);
		}
		setLoading(false);
	}

	useEffect(() => {
		getUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleShowChange = (e) => {
		setIsCompleted(false);
		setShowName(e.target.value);
	}

	useEffect(() => {
		function validateGoogleUrlLink(link) {
			try {
				const isGoogleUrlValid = link.split('/')[2];
				if (isGoogleUrlValid === 'drive.google.com') {
					return true;
				}
				return false;
			} catch (err) {
				return false;
			}
		}

		if (validateGoogleUrlLink(link) && showDate) {
			setIsValidated(true);
		} else {
			setIsValidated(false);
		}
	}, [showName, link, showDate]);

	if (loading) return <span>loading..</span>;

	if (error.isError) return <span>{error.errorMsg}</span>;

	function getFinalShowName() {
		return isCustom ? customShowName : showName;
	}

	return (
		<div>
			<div className={styles.section}>
				Upload an upcoming show
				<br />
			</div>

			{!isCustom && <div className={styles.section}>
				<span className={styles.label}>Show name: </span>
				<select disabled={doesUserHaveAssociatedShow && !isAdmin} value={showName} onChange={handleShowChange}>
					{shows.map((show, i) => {
						return (
							<option key={i} value={show.name}>
								{show.name}
							</option>
						);
					})}
				</select>
			</div>}

			{
				isAdmin &&
				<div className={styles.section}>
					<span className={styles.label}>Custom show name (use this section for shows that do not have a show bio defined, for example initial
					trial episodes or special events, etc): </span>
					<br />
					<br />
					<input name="custom" type="checkbox" checked={isCustom} onChange={() => { setIsCompleted(false); setIsCustom(!isCustom) }} />
					<br />
					<br />
					{isCustom && <input value={customShowName} onChange={(e) => { setIsCompleted(false); setCustomShowName(e.target.value); }} className={styles.input} />}
					<br />
				</div>
			}

			<div className={styles.section}>
				<span className={styles.label}>
					Google drive url (copy whole link from share interface, must be shared publicly, include whole url
					including https://):{' '}
				</span>
				<input value={link} onChange={(e) => { setIsCompleted(false); setLink(e.target.value); }} className={styles.input} />
			</div>
			<div className={styles.section}>
				<br />
				Date of your upcoming show
				<br />
				<br />
				<span className={styles.label}>date: </span>
				<DateTime
					type="date"
					name="date"
					minDate={new Date()}
					selected={showDate}
					onChange={(value) => { setIsCompleted(false); setShowDate(value); }}
				/>
			</div>
			<div className={styles.section}>
				<div>
					<button
						disabled={!isValidated || isCompleted}
						onClick={(e) =>
							apiUploadShow(
								{
									associatedShow: getFinalShowName(),
									link: link,
									broadcastDate: showDate,
								},
								e
							)
						}
						className={styles.button}
					>
						Submit Show
					</button>
					<p className={styles.success}>
						{isCompleted ? `${getFinalShowName()} for ${showDate} is now uploading. Please view uploads queue status page to view status of your uploads.` : null}
					</p>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		_id: state._user._id,
	};
};

const ConnectedProfile = connect(mapStateToProps, {})(UserUploadGoogleDrive);

export default ConnectedProfile;
