import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./AudioPlayer.module.css";

import play from "./icons_play.png";
import pause from "./icons_pause.png";

import {
  playLive,
  pauseLive,
  playArchive,
  pauseArchive,
} from "../../../redux/modules/audio";

/**
 * @author  Conan Lai
 * @description play/pause button, plays or pauses live or archive widget depending
 * on source
 */

class PlayPause extends React.Component {
  constructor(props) {
    super(props);
    this.toggleAudio = this.toggleAudio.bind(this);
  }

  toggleAudio() {
    const isLiveMode =
      this.props.source === "live" ||
      this.props.source === "pausedlive" ||
      this.props.source === "none";
    if (isLiveMode) {
      this.props.toggleLive();
    } else if (this.props.source === "archive") {
      this.props.pauseArchive();
    } else if (this.props.source === "pausedarchive") {
      this.props.playArchive();
    }
  }

  render() {
    const img =
      this.props.source === "live" || this.props.source === "archive"
        ? pause
        : play;
    return (
      <img className={styles.playbutton} onClick={this.toggleAudio} src={img} />
    );
  }
}

PlayPause.propTypes = {
  source: PropTypes.string,
  playLive: PropTypes.func,
  pauseLive: PropTypes.func,
  playArchive: PropTypes.func,
  pauseArchive: PropTypes.func,
  toggleLive: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    isMobile: state._ui.isMobile,
    source: state._audio.source,
  };
};

const ConnectedPlayPause = connect(mapStateToProps, {
  playLive,
  pauseLive,
  playArchive,
  pauseArchive,
})(PlayPause);

export default ConnectedPlayPause;
