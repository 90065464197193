import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import styles from "./ScheduleShow.module.css";

import {
  bringShowToFront,
  changeShow,
} from "../../../../redux/modules/content/show";

import { incrementTopZ } from "../../../../redux/modules/ui";

/**
 * @author  Conan Lai
 * @description displays a single row in the schedule
 * splitting the string to form time and name,
 * if name is too long, render a Marquee
 * use Moment to display date in user's timezone
 */

class ScheduleShow extends React.PureComponent {
  render() {
    let slug;
    for (let i = 0; i < this.props.shows.length; i += 1) {
      if (
        this.props.name
          .toLowerCase()
          .includes(this.props.shows[i].name.toLowerCase())
      ) {
        slug = this.props.shows[i].slug;
        break;
      }
    }
    const easterntime = moment.tz(
      this.props.start_timestamp,
      "America/New_York"
    );
    const localtime = moment
      .tz(easterntime, this.props.timezone)
      .format()
      .slice(11, 16);
    let name;
    let rerun;
    if (this.props.name.toLowerCase().includes("re-run")) {
      name = this.props.name.slice(0, -9);
      rerun = "(R)";
    } else {
      name = this.props.name;
    }
    return (
      <div
        onClick={() => {
          if (slug) {
            this.props.changeShow(slug);
            this.props.closeSchedule();
            if (this.props.topZIndex > this.props.z) {
              const newZ = this.props.topZIndex + 1;
              this.props.bringShowToFront(newZ);
              this.props.incrementTopZ();
            }
          }
        }}
        style={{ cursor: slug ? "crosshair" : "auto" }}
        className={
          this.props.current === this.props.name ? styles.current : styles.show
        }
      >
        <div className={styles.time}>{localtime}</div>
        <div className={styles.name}>
          <span dangerouslySetInnerHTML={{ __html: name }} />
        </div>
        <span className={styles.rerun}>{rerun}</span>
        <div className={styles.clear} />
      </div>
    );
  }
}

ScheduleShow.propTypes = {
  name: PropTypes.string,
  start_timestamp: PropTypes.string,
  shows: PropTypes.array,
  current: PropTypes.string,
  changeShow: PropTypes.func,
  bringShowToFront: PropTypes.func,
  incrementTopZ: PropTypes.func,
  z: PropTypes.number,
  topZIndex: PropTypes.number,
  closeSchedule: PropTypes.func,
  timezone: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    shows: state._shows.shows,
    current: state._airtime.current,
    z: state._show.z,
    topZIndex: state._ui.topZIndex,
  };
};
const ConnectedScheduleShow = connect(mapStateToProps, {
  bringShowToFront,
  changeShow,
  incrementTopZ,
})(ScheduleShow);

export default ConnectedScheduleShow;
