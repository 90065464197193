import { SignIn, retrieveUserFromLocalStorage, signOutUser } from '../../components/Login/api/cognito/SignIn';

export default function reducer(
	state = {
		authenticated: false,
		_id: null,
		user: null,
		userType: null,
		associatedShow: null,
		email: '',
		redirect: false,
		coginitoUserLoading: null,
		cognitoUserError: false,
		errorMsg: '',
	},
	action
) {
	switch (action.type) {
		case 'AUTH_USER':
			return {
				...state,
				authenticated: true,
			};
		case 'SET_ID':
			return {
				...state,
				_id: action.payload,
			};
		case 'SET_USER_TYPE':
			return {
				...state,
				userType: action.payload,
			};
		case 'SET_ASSOCIATED_SHOW':
			return {
				...state,
				associatedShow: action.payload,
			};
		case 'UNAUTH_USER':
			return {
				...state,
				authenticated: false,
				user: null,
				email: '',
				userType: null,
			};
		case 'COGNITO_USER_LOADING':
			return {
				...state,
				coginitoUserLoading: true,
			};
		case 'COGNITO_USER_COMPLETED':
			return {
				...state,
				cognitoUserError: false,
				coginitoUserLoading: false,
				errorMsg: '',
			};
		case 'COGNITO_USER_ERROR':
			return {
				...state,
				coginitoUserLoading: false,
				cognitoUserError: true,
				errorMsg: action.payload,
			};
		case 'SET_USER':
			return {
				...state,
				user: action.payload,
			};
		case 'SET_EMAIL':
			return {
				...state,
				email: action.payload,
			};
		default:
			return state;
	}
}

export function cognitoLoading() {
	return function (dispatch) {
		dispatch({
			type: 'COGNITO_USER_LOADING',
		});
	};
}

export function logoutUserFromReduxState() {
	return function (dispatch) {
		dispatch({
			type: 'UNAUTH_USER',
		});
	};
}

export function setUser(user) {
	return function (dispatch) {
		dispatch({
			type: 'SET_USER',
			payload: user,
		});
		dispatch({
			type: 'AUTH_USER',
		});
		dispatch({
			type: 'COGNITO_USER_COMPLETED',
		});
	};
}

export function setId(id) {
	return function (dispatch) {
		dispatch({
			type: 'SET_ID',
			payload: id,
		});
	};
}

export function handleError(errorMsg) {
	return function (dispatch) {
		dispatch({
			type: 'UNAUTH_USER',
		});
		dispatch({
			type: 'COGNITO_USER_ERROR',
			payload: errorMsg,
		});
	};
}

export function handleCognitoSignIn(email, password) {
	return function (dispatch) {
		try {
			dispatch({
				type: 'COGNITO_USER_LOADING',
			});
			SignIn(email, password).then((session) => {
				switch (session.status) {
					case 200:
						dispatch({
							type: 'AUTH_USER',
						});
						dispatch({
							type: 'SET_USER',
							payload: session,
						});
						dispatch({
							type: 'SET_USER_TYPE',
							payload: session.userType,
						});
						dispatch({
							type: 'SET_ID',
							payload: session._id,
						});
						dispatch({
							type: 'SET_EMAIL',
							payload: session.info.idToken.payload.email,
						});
						dispatch({
							type: 'COGNITO_USER_COMPLETED',
						});
						break;
					case 400:
						dispatch({
							type: 'UNAUTH',
						});
						dispatch({
							type: 'COGNITO_USER_ERROR',
							payload: session.msg,
						});
						break;
					default:
						dispatch({
							type: 'UNAUTH',
						});
						dispatch({
							type: 'COGNITO_USER_ERROR',
							payload: 'Unknown error',
						});
				}
			});
		} catch (err) {
			dispatch({
				type: 'UNAUTH',
				payload: 'unknown error',
			});
			dispatch({
				type: 'COGNITO_USER_ERROR',
				payload: 'unknown error',
			});
		}
	};
}

export function retrieveCognitoUserFromLocalStorage() {
	return function (dispatch) {
		try {
			dispatch({
				type: 'COGNITO_USER_LOADING',
			});
			retrieveUserFromLocalStorage().then(
				(session) => {
					dispatch({
						type: 'AUTH_USER',
					});
					dispatch({
						type: 'SET_USER',
						payload: session.user,
					});
					dispatch({
						type: 'SET_ID',
						payload: session._id,
					});
					dispatch({
						type: 'SET_USER_TYPE',
						payload: session.userType,
					});
					dispatch({
						type: 'SET_ASSOCIATED_SHOW',
						payload: session.associatedShow,
					});
					dispatch({
						type: 'SET_EMAIL',
						payload: session.user.idToken.payload.email,
					});
					dispatch({
						type: 'COGNITO_USER_COMPLETED',
					});
				},
				(err) => {
					dispatch({
						type: 'UNAUTH',
						payload: err,
					});
				}
			);
		} catch (err) {
			dispatch({
				type: 'UNAUTH',
				payload: 'No local storage to retrieve token',
			});
			dispatch({
				type: 'COGNITO_USER_ERROR',
				payload: 'unknown error',
			});
		}
	};
}

export function signOut() {
	return function (dispatch) {
		signOutUser().then((res) => {
			dispatch({
				type: 'UNAUTH_USER',
				payload: 'logged out',
			});
		});
	};
}

export function setUserToReduxState(user) {
	return function (dispatch) {
		dispatch({
			type: 'AUTH_USER',
		});
		dispatch({
			type: 'SET_USER',
			payload: user,
		});
	};
}
