import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./FeelingLucky.module.css";

import { changeArchive } from "../../../../../redux/modules/archives";

class FeelingLucky extends React.PureComponent {
  constructor(props) {
    super(props);
    this.selectRandom = this.selectRandom.bind(this);
  }

  selectRandom() {
    const rando = Math.floor(Math.random() * this.props.archives.length);
    const { key } = this.props.archives[rando];
    window.open(`https://www.mixcloud.com${key}`, "_blank");
    this.props.closeArchive();
  }

  render() {
    return (
      <div className={styles.row} onClick={this.selectRandom}>
        <div className={styles.name}>I'M FEELING LUCKY</div>
      </div>
    );
  }
}

FeelingLucky.propTypes = {
  slug: PropTypes.string,
  name: PropTypes.string,
  archives: PropTypes.array,
  changeArchive: PropTypes.func,
  closeArchive: PropTypes.func,
};

const ConnectedFeelingLucky = connect(null, { changeArchive })(FeelingLucky);

export default ConnectedFeelingLucky;
