import React from 'react';
import EditShow from './Show/EditShow';
import EditNewEvent from './NewEvent/EditNewEvent';
import EditShop from './Shop/EditShop';
import EditAnnouncement from './Announcement/EditAnnouncement';
import EditBackgrounds from './Backgrounds/EditBackgrounds';
import InstagramAccess from './InstagramAccess/InstagramAccess';
import styles from './AdminEditor.module.css';

import UserUploadGoogleDrive from '../AdminEditor/UserUploadGoogleDrive/UserUploadGoogleDrive';
import AdminUserRole from '../AdminEditor/AdminUserRole/AdminUserRole';
import UserAssociatedShow from '../AdminEditor/UserAssociateShow/UserAssociateShow';
import CreateUser from '../AdminEditor/CreateUser/CreateUser';

// AdminEditor is container for the individual admin functions
// renders correct component based on editorMode passed in

class AdminEditor extends React.Component {
	render() {
		let editorContent = '';
		switch (this.props.editorMode) {
			case 'CreateUser':
				editorContent = <CreateUser {...this.props} />;
				break;
			case 'AddUploadShow':
				editorContent = <UserUploadGoogleDrive editMode="edit" {...this.props} />;
				break;
			case 'EditUserAdminRole':
				editorContent = <AdminUserRole editMode="edit" {...this.props} />;
				break;
			case 'EditAssociateShowWithUser':
				editorContent = <UserAssociatedShow editMode="edit" {...this.props} />;
				break;
			case 'AddShow':
				editorContent = <EditShow editMode="add" {...this.props} />;
				break;
			case 'EditShow':
				editorContent = <EditShow editMode="edit" {...this.props} />;
				break;
			case 'EditNewEvent':
				editorContent = <EditNewEvent editMode="edit" {...this.props} />;
				break;
			case 'AddNewEvent':
				editorContent = <EditNewEvent editMode="add" {...this.props} />;
				break;
			case 'EditShop':
				editorContent = <EditShop editMode="edit" {...this.props} />;
				break;
			case 'AddShop':
				editorContent = <EditShop editMode="add" {...this.props} />;
				break;
			case 'EditAnnouncement':
				editorContent = <EditAnnouncement editMode="edit" {...this.props} />;
				break;
			case 'AddAnnouncement':
				editorContent = <EditAnnouncement editMode="add" {...this.props} />;
				break;
			case 'EditBackgrounds':
				editorContent = <EditBackgrounds {...this.props} />;
				break;
			case 'GetInstagramAccess':
				editorContent = <InstagramAccess {...this.props} />;
				break;
			default:
				editorContent = 'nothing selected';
				break;
		}
		return <div className={styles.admineditor}>{editorContent}</div>;
	}
}

export default AdminEditor;
