import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Schedule from "./Schedule";

import { closeSchedule } from "../../../../redux/modules/content/schedule";

/**
 * @author  Conan Lai
 * @description fetches schedule info from Airtime API
 */

class ScheduleHolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
    this.fetchSchedule = this.fetchSchedule.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchSchedule();
    if (process.env.NODE_ENV === "production") {
      setInterval(this.fetchSchedule, 30000);
    }
  }

  componentWillUnmount() {
    // we use this pattern to prevent calling setState() after component unmounts
    this.mounted = false;
  }

  fetchSchedule() {
    fetch("https://n10as.airtime.pro/api/week-info")
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (this.mounted === true) {
          this.setState({ data: json });
        }
      });
  }

  render() {
    const schedule =
      this.props.open === true ? (
        <Schedule
          data={this.state.data}
          open={this.props.open}
          closeSchedule={this.props.closeSchedule}
          selectedDay={this.props.selectedDay}
          timezone={this.props.timezone}
        />
      ) : null;
    return schedule;
  }
}

ScheduleHolder.propTypes = {
  open: PropTypes.bool,
  selectedDay: PropTypes.string,
  timezone: PropTypes.string,
  closeSchedule: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    open: state._schedule.open,
    selectedDay: state._schedule.selectedDay,
    timezone: state._ui.timezone,
  };
};

const ConnectedScheduleHolder = connect(mapStateToProps, { closeSchedule })(
  ScheduleHolder
);

export default ConnectedScheduleHolder;
