export default function reducer(state = {
  open: false,
  selectedArchiveSlug: 'none',
  selectedArchiveName: 'none',
}, action = {}) {
  switch (action.type) {
    case 'CHANGE_ARCHIVE':
      return {
        ...state,
        selectedArchiveSlug: action.slug,
        selectedArchiveName: action.name,
      };
    case 'OPEN_ARCHIVE':
      return {
        ...state,
        open: true,
      };
    case 'CLOSE_ARCHIVE':
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}

export const changeArchive = (slug, name) => {
  return {
    type: 'CHANGE_ARCHIVE',
    slug,
    name,
  };
};

export const openArchive = () => {
  return {
    type: 'OPEN_ARCHIVE',
  };
};

export const closeArchive = () => {
  return {
    type: 'CLOSE_ARCHIVE',
  };
};
