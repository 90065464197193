import { combineReducers } from "redux";

// import language from './modules/language';
import airtime from "./modules/airtime";
import archives from "./modules/archives";
import audio from "./modules/audio";
import background from "./modules/background";
import ui from "./modules/ui";

import about from "./modules/content/about";
import bulletinboard from "./modules/content/bulletinboard";
import chat from "./modules/content/chat";
import instagram from "./modules/content/instagram";
import schedule from "./modules/content/schedule";
import shop from "./modules/content/shop";
import show from "./modules/content/show";
import shows from "./modules/content/shows";
import user from "./modules/user";

export default combineReducers({
  _airtime: airtime,
  _archives: archives,
  _audio: audio,
  _background: background,
  _ui: ui,
  _about: about,
  _bulletinboard: bulletinboard,
  _chat: chat,
  _instagram: instagram,
  _schedule: schedule,
  _shop: shop,
  _show: show,
  _shows: shows,
  _user: user,
});
