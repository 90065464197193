import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Archives from "./Archives";

import { openArchive, closeArchive } from "../../../../redux/modules/archives";

/**
 * @author  Conan Lai
 * @description fetches archives from API
 */

class ArchivesHolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    this.mounted = true;
    fetch(this.props.url)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (this.mounted === true) {
          this.setState({ data: json });
        }
      });
  }

  componentWillUnmount() {
    // we use this pattern to prevent calling setState() after component unmounts
    this.mounted = false;
  }

  render() {
    const archives =
      this.props.open === true ? (
        <Archives
          data={this.state.data}
          open={this.props.open}
          closeArchive={this.props.closeArchive}
        />
      ) : null;
    return archives;
  }
}

ArchivesHolder.propTypes = {
  open: PropTypes.bool,
  closeArchive: PropTypes.func,
  url: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    open: state._archives.open,
  };
};

const ConnectedArchivesHolder = connect(mapStateToProps, {
  openArchive,
  closeArchive,
})(ArchivesHolder);

export default ConnectedArchivesHolder;
