import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Marquee from "../../../lib/Marquee";
import styles from "./NextPlaying.module.css";

/**
 * @author  Conan Lai
 * @description displays next show playing
 */

class NextPlaying extends React.Component {
  render() {
    return (
      <div className={styles.nextplaying}>
        <Marquee>
          <span dangerouslySetInnerHTML={{ __html: this.props.next }} />
        </Marquee>
      </div>
    );
  }
}

NextPlaying.propTypes = {
  next: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    next: state._airtime.next,
  };
};

const ConnectedNextPlaying = connect(mapStateToProps)(NextPlaying);

export default ConnectedNextPlaying;
