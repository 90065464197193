import React from "react";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import SchedulePicker from "./SchedulePicker/SchedulePicker";
import ScheduleShow from "./ScheduleShow";
import styles from "./Schedule.module.css";

/**
 * @author  Conan Lai
 * @description displays list of shows in an archive day
 */

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    this.props.closeSchedule();
  }

  render() {
    const shows = this.props.data[this.props.selectedDay] || [];
    return (
      <div className={styles.wrapper}>
        <SchedulePicker />
        {shows.map((show) => {
          return (
            <ScheduleShow
              key={show.name}
              {...show}
              closeSchedule={this.props.closeSchedule}
              timezone={this.props.timezone}
            />
          );
        })}
      </div>
    );
  }
}

Schedule.propTypes = {
  data: PropTypes.object,
  selectedDay: PropTypes.string,
  timezone: PropTypes.string,
  shows: PropTypes.array,
  closeSchedule: PropTypes.func,
};
const ConnectedClickOutside = onClickOutside(Schedule);

export default ConnectedClickOutside;
