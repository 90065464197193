import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { validateCognitoUser } from '../getBackendResource';
import { cognito_user_pool_id, cognito_client_id } from '../../../../config';

export function SignIn(email, password, userPoolId = cognito_user_pool_id, clientId = cognito_client_id) {
	const promisfySignIn = new Promise((resolve, reject) => {
		try {
			const authenticationData = {
				Username: email,
				Password: password,
			};
			const authenticationDetails = new AuthenticationDetails(authenticationData);
			const poolData = {
				UserPoolId: userPoolId,
				ClientId: clientId,
			};
			const userPool = new CognitoUserPool(poolData);
			const userData = {
				Username: authenticationData.Username,
				Pool: userPool,
			};
			const cognitoUser = new CognitoUser(userData);
			cognitoUser.authenticateUser(authenticationDetails, {
				onFailure: (error) => {
					resolve({
						status: 400,
						msg: error.message,
					});
				},
				newPasswordRequired: function (userAttributes, requiredAttributes) {
					delete userAttributes.email_verified;
					cognitoUser.completeNewPasswordChallenge(password, userAttributes, this);
				},
				onSuccess: (body) => {
					const accessToken = body.getAccessToken().getJwtToken();
					const { email_verified } = body.idToken.payload;
					if (email_verified) {
						validateCognitoUser(accessToken, email).then((result) => {
							const { userType, isUserValidated, _id } = result;
							if (isUserValidated) {
								localStorage.setItem('user_token', accessToken);
								resolve({
									status: 200,
									jwt: accessToken,
									info: body,
									_id,
									userType: userType,
								});
							} else {
								reject('Could not validate token with associated user');
							}
						});
					}
				},
			});
		} catch (err) {
			reject(err);
		}
	});
	return promisfySignIn;
}

export function retrieveUserFromLocalStorage(userPoolId = cognito_user_pool_id, clientId = cognito_client_id) {
	const userPool = new CognitoUserPool({
		UserPoolId: userPoolId,
		ClientId: clientId,
	});

	const p = new Promise((res, rej) => {
		const cognitoUser = userPool.getCurrentUser();
		if (cognitoUser != null) {
			cognitoUser.getSession(function (err, session) {
				if (err) {
					rej(err);
				}
				validateCognitoUser(session.getAccessToken().getJwtToken()).then((result) => {
					const { userType, isUserValidated, _id, associatedShow } = result;
					if (isUserValidated) {
						localStorage.setItem('user_token', session.getAccessToken().getJwtToken());
						res({
							user: session,
							status: 200,
							userType: userType,
							associatedShow,
							_id,
						});
					} else {
						rej('Failed to validate user');
					}
				});
			});
		} else {
			rej('Failed to retrieve user from localStorage');
		}
	});
	return p;
}

export function signOutUser(userPoolId = cognito_user_pool_id, clientId = cognito_client_id) {
	const userPool = new CognitoUserPool({
		UserPoolId: userPoolId,
		ClientId: clientId,
	});
	const p = new Promise((res, rej) => {
		const cognitoUser = userPool.getCurrentUser();
		cognitoUser.signOut();
		res(`logout completed`);
	});
	return p;
}
