import React, { Component } from 'react';
import { connect } from 'react-redux';
import { retrieveCognitoUserFromLocalStorage } from '../../../redux/modules/user';

export default function (ComposedComponent) {
	class Authentication extends Component {
		componentWillMount() {
			if (!this.props.authenticated) {
				// this.context.router.push('/auth/login')
			}
		}

		// componentWillUpdate() is called each time the component gets a new set of props or re-rendered
		// the first arg `nextProps` defines the next set of properties a component will get rendered with
		componentWillUpdate(nextProps) {
			// to clear the text on the `/resources` page after signing out, push url to the home route
			if (!nextProps.authenticated) {
			}
		}
		render() {
			return (
				<React.Fragment>
					<ComposedComponent {...this.props} />
				</React.Fragment>
			);
		}
	}

	function mapStateToProps(state) {
		return {
			authenticated: state._user.authenticated,
		};
	}
	return connect(mapStateToProps, { retrieveCognitoUserFromLocalStorage })(Authentication);
}
