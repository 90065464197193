import React, { useEffect } from 'react';
import config from '../../../../config';
import styles from '../AdminEditor.module.css';
import ConnectedArchivesAndSchedule from '../../../TopBar/ArchivesAndSchedule/ArchivesAndSchedule'

const userTypesArray = [
	{ value: 'guest', label: 'guest ' },
	{ value: 'show-host', label: 'show-host' },
	{ value: 'admin', label: 'admin' },
];

export default function CreateUser({ shows }) {
	const [selectedUserType, setSelectedUserType] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [username, setUsername] = React.useState('');
	const [isAssociatedShow, setIsAssociatedShow] = React.useState('');
	const [selectedShow, setSelectedShow] = React.useState('');

	const [isSubmissionValid, setIsSubmissionValid] = React.useState(false);
	const [isCompletedMsg, setIscompletedMsg] = React.useState('');
	const [error, setError] = React.useState('');

	async function apiCreateUser() {
		const url = `${config.api_path}/users/`;
		const body = {
			email,
			username,
			userType: selectedUserType,
		};
		if (isAssociatedShow) {
			body.associatedShow = selectedShow;
		}
		console.log(body);
		const response = await fetch(url, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		});
		if (response.status === 200) {
			setIscompletedMsg(`${username} has been created`);
			setError(``);
		} else {
			setIscompletedMsg(``);
			setError(`It looks like we have a an error, ooops ${response.status}`);
		}
	}

	useEffect(() => {
		if (selectedUserType && email && username) {
			setIsSubmissionValid(true);
		}
	}, [isSubmissionValid, email, username, selectedUserType]);

	return (
		<React.Fragment>
			<div className={styles.section}>
				<span>Create a new user: </span>
				<br />
				<br />
				Users will have a password generated for them and their login information will be e-mailed to them.
				<br />
				<br />
				The username can be anything (as long as it doesn't already exist in our system), with no spaces. Good ideas could be their full name, artist name, etc
				<br />
				<br />
				Users are assigned a user type - guests have no permissions (though we can give permissions in the future), show hosts can upload episodes
				and view their own pending uploads, and admins have access to all content editing (shows/events/etc) as well as viewing all pending uploads.
				<br />
				<br />
				If they are a show host, you likely want to associate them to a specific show. This will make it so they can only upload and view episodes of their own show.
				<br />
				You might want to not assign a 'show-host' a specific show if you're using this user type for a staff member that will only upload/manage episodes for any show.
				<br />
				<br />
				<div>
					Email:
					<input
						type="email"
						onChange={(e) => { setEmail(e.target.value); }}
						value={email}
						name="email"
					/>
				</div>
				<br />
				<br />
				<div>
					Username:
					<input
						type="username"
						onChange={(e) => { setUsername(e.target.value); }}
						value={username}
						name="username"
					/>
				</div>
				<br />
				<br />
				<div>
					User Type:
					<select
						style={{ marginLeft: '1em' }}
						onChange={(e) => setSelectedUserType(e.currentTarget.value)}
						value={selectedUserType}
					>
						{userTypesArray.map((item) => (
							<option key={item.value} value={item.value}>
								{item.label}
							</option>
						))}
					</select>
				</div>
				<br />
				<br />
				<div>
					Check here if user should have a show associated with them
					<br/>
					<input name="custom" type="checkbox" checked={isAssociatedShow} onChange={() => { setIsAssociatedShow(!isAssociatedShow); }} />
				</div>
				<br />
				<br />
				{isAssociatedShow &&
					<div>
						<select
							style={{ marginLeft: '1em' }}
							onChange={(e) => setSelectedShow(e.currentTarget.value)}
							value={selectedShow}
						>
							{shows.map((item) => (
								<option key={item.name} value={item.name}>
									{`${item.name}`}
								</option>
							))}
						</select>
					</div>}
				<br />
				<br />
				<div>
					<button
						style={{ marginTop: '0.5em' }}
						type="submit"
						onClick={apiCreateUser}
						disabled={!isSubmissionValid}
					>
						Submit
					</button>
					<span>{error}</span>
					<span style={{ color: 'green' }}> {isCompletedMsg}</span>
				</div>
			</div>
		</React.Fragment>
	);
}
