export default function reducer(state = {
  open: false,
  selectedDay: '',
}, action = {}) {
  switch (action.type) {
    case 'OPEN_SCHEDULE':
      return {
        ...state,
        open: true,
        selectedDay: action.day,
      };
    case 'CLOSE_SCHEDULE':
      return {
        ...state,
        open: false,
        selectedDay: '',
      };
    default:
      return state;
  }
}

export const openSchedule = (day) => {
  return {
    type: 'OPEN_SCHEDULE',
    day,
  };
};

export const closeSchedule = (e) => {
  return {
    type: 'CLOSE_SCHEDULE',
  };
};
