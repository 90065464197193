import React from "react";
import PropTypes from "prop-types";
import styles from "./RangePicker.module.css";

/**
 * @author  Conan Lai
 * @description renders an html range input slider
 * can be used for opacity slider, volume slider, etc
 * takes a handleChange callback prop, which the parent component
 * can use to set state in redux, etc
 * also takes a vertical prop to determine styling
 */

class RangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.handleInput = this.handleInput.bind(this);
  }

  handleInput(event) {
    const value = event.target.value;
    this.props.handleChange(value);
  }

  render() {
    const className = this.props.vertical ? styles.vertical : styles.horizontal;
    return (
      <input
        className={className}
        type="range"
        value={this.props.value}
        min={0}
        max={100}
        step={1}
        onChange={this.handleInput}
      />
    );
  }
}

RangePicker.propTypes = {
  vertical: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default RangePicker;
