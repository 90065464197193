import { closeBulletinBoard } from './content/bulletinboard';
import { closeChat } from './content/chat';
import { closeInstagram } from './content/instagram';
import { closeShop } from './content/shop';
import { closeShows } from './content/shows';
import { closeShow } from './content/show';
import { closeAbout } from './content/about';
import { closeSchedule } from './content/schedule';
import { closeArchive } from './archives';

export default function reducer(state = {
  landedSlug: '',
  isMobile: false,
  mobileOrientation: 'default',
  foundWindowSize: false,
  windowHeight: 0,
  windowWidth: 0,
  topZIndex: 7,
  opacity: 100,
  timezone: 'America/New_York',
}, action = {}) {
  switch (action.type) {
    case 'FIND_WINDOW_PROPERTIES':
      return {
        ...state,
        landedSlug: action.slug,
        mobileOrientation: action.mobileOrientation,
        windowHeight: action.windowHeight,
        windowWidth: action.windowWidth,
        foundWindowSize: true,
        isMobile: action.isMobile,
      };
    case 'RESIZE_WINDOW':
      return {
        ...state,
        windowHeight: action.height,
        windowWidth: action.width,
      };
    case 'CHANGE_MOBILE_ORIENTATION':
      return {
        ...state,
        mobileOrientation: action.value,
      };
    case 'CHANGE_OPACITY':
      return {
        ...state,
        opacity: Number(action.value),
      };
    case 'INCREMENT_TOP_Z':
      return {
        ...state,
        topZIndex: state.topZIndex + 1,
      };
    case 'CHANGE_TIME_ZONE':
      return {
        ...state,
        timezone: action.timezone,
      };
    default:
      return state;
  }
}

export const detectMobileOrientation = (height, width) => {
  if (width > height) {
    return 'landscape';
  }
  return 'portrait';
};

export const findWindowProperties = (slug) => {
  const mql = window.matchMedia('(max-width: 750px)');
  let isMobile = false;
  let mobileOrientation = 'default';
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && mql.matches) {
    isMobile = true;
  }
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  if (isMobile) {
    mobileOrientation = detectMobileOrientation(windowHeight, windowWidth);
  }
  return {
    type: 'FIND_WINDOW_PROPERTIES',
    slug,
    mobileOrientation,
    windowHeight,
    windowWidth,
    foundWindowSize: true,
    isMobile,
  };
};

export const resizeWindow = (height, width) => {
  return {
    type: 'RESIZE_WINDOW',
    height,
    width,
  };
};

export const changeMobileOrientation = (orientation) => {
  return {
    type: 'CHANGE_MOBILE_ORIENTATION',
    value: orientation,
  };
};

export const changeOpacity = (value) => {
  return {
    type: 'CHANGE_OPACITY',
    value,
  };
};

export const incrementTopZ = () => {
  return {
    type: 'INCREMENT_TOP_Z',
  };
};

export const changeTimeZone = (timezone) => {
  return {
    type: 'CHANGE_TIME_ZONE',
    timezone,
  };
};

export const closeAll = () => {
  return (dispatch) => {
    dispatch(closeBulletinBoard());
    dispatch(closeChat());
    dispatch(closeInstagram());
    dispatch(closeShop());
    dispatch(closeShows());
    dispatch(closeShow());
    dispatch(closeAbout());
    dispatch(closeArchive());
  };
};
