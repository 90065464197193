import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import EventTicker from "./EventTicker/EventTicker";
import BackToLive from "./BackToLive/BackToLive";
import styles from "./Footer.module.css";

import { playLive } from "../../redux/modules/audio";

/**
 * @author  Conan Lai
 * @description footer containing EventTicker
 * or on mobile when archive player open, displays BACK TO LIVE buttons
 */

class Footer extends React.Component {
  render() {
    let content;
    let style = styles.footer;
    if (
      this.props.isMobile &&
      (this.props.source === "archive" || this.props.source === "pausedarchive")
    ) {
      content = <BackToLive playLive={this.props.playLive} />;
      style += " " + styles.backtolive;
    } else {
      content = <EventTicker />;
    }
    return (
      <div className={style}>
        <div id={styles.eventticker}>{content}</div>
      </div>
    );
  }
}

Footer.propTypes = {
  playLive: PropTypes.func,
  source: PropTypes.string,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    source: state._audio.source,
    isMobile: state._ui.isMobile,
  };
};

const ConnectedFooter = connect(mapStateToProps, { playLive })(Footer);

export default ConnectedFooter;
