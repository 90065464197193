import { api_path } from "../../../config";

export function validateCognitoUser(token, email) {
  return fetch(`${api_path}/users/validate-login`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      jwt: token,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify({
      token,
      email,
    }),
  }).then((result) => {
    return result.json()
  })
}
