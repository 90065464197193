import React from "react";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import ArchiveShow from "./ArchiveShow";
import FeelingLucky from "./FeelingLucky/FeelingLucky";
import styles from "./Archives.module.css";

/**
 * @author  Conan Lai
 * @description renders list of archvies and Feeling Lucky button
 */

class Archives extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    this.props.closeArchive();
  }

  render() {
    const archives = this.props.data.data || [];
    return (
      <div className={styles.wrapper}>
        <FeelingLucky
          archives={archives}
          closeArchive={this.props.closeArchive}
        />
        {archives.map((archive) => {
          const slug = archive.key;
          return (
            <ArchiveShow
              {...archive}
              slug={slug}
              closeArchive={this.props.closeArchive}
            />
          );
        })}
      </div>
    );
  }
}

Archives.propTypes = {
  data: PropTypes.object,
  closeArchive: PropTypes.func,
};

const ConnectedArchives = onClickOutside(Archives);

export default ConnectedArchives;
