import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Icons from "./Icons";
import ChatIcon from "./Icons/ChatIcon";
import BulletinIcon from "./Icons/BulletinIcon";
import ShowsIcon from "./Icons/ShowsIcon";
import ShopIcon from "./Icons/ShopIcon";
import InstaIcon from "./Icons/InstaIcon";
// import VideoIcon from './Icons/VideoIcon';
import styles from "./SideBar.module.css";

import InfoPanel from "./InfoPanel/InfoPanel";
import ArchivesIcon from "./Icons/Mobile/ArchivesIcon";
import ScheduleIcon from "./Icons/Mobile/ScheduleIcon";

/**
 * @author  Conan Lai
 * @description sidebar, transformed and rotated on mobile
 * info panel loads async and only on desktop
 */

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.renderDesktop = this.renderDesktop.bind(this);
    this.renderMobile = this.renderMobile.bind(this);
  }

  renderDesktop() {
    return (
      <div className={styles.sidebar}>
        <div className={styles.infopanel}>
          <InfoPanel />
        </div>
        <Icons>
          <ChatIcon />
          <ShowsIcon />
          <BulletinIcon />
          <ShopIcon />
          <InstaIcon />
        </Icons>
        <div className={styles.social}>
          <a target="blank" href="https://www.facebook.com/n10asradio/">
            <p className={styles.fb}>fb</p>
          </a>
          <a target="blank" href="https://www.mixcloud.com/n10as/">
            <p className={styles.mc}>mc</p>
          </a>
          <a target="blank" href="https://x.com/n10asradio">
            <p className={styles.tw}>x</p>
          </a>
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div className={styles.sidebar}>
        <Icons>
          <ChatIcon />
          <ShowsIcon />
          <BulletinIcon />
          <ShopIcon />
          <InstaIcon />
          <ArchivesIcon />
          <ScheduleIcon />
        </Icons>
      </div>
    );
  }

  render() {
    let content = <div className={styles.sidebar} />;
    // only render once screen size determined
    if (this.props.foundWindowSize) {
      if (this.props.isMobile !== true) {
        content = this.renderDesktop();
      } else {
        content = this.renderMobile();
      }
    }
    return content;
  }
}

SideBar.propTypes = {
  isMobile: PropTypes.bool,
  foundWindowSize: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    foundWindowSize: state._ui.foundWindowSize,
    isMobile: state._ui.isMobile,
  };
};

const ConnectedSideBar = connect(mapStateToProps)(SideBar);

export default ConnectedSideBar;
