import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./HelpButton.module.css";

import icon from "./icons_help.png";

import { bringAboutToFront } from "../../../redux/modules/content/about";

import { incrementTopZ } from "../../../redux/modules/ui";

/**
 * @author  Conan Lai
 * @description help button in top right opens About page
 */

class HelpButton extends React.Component {
  constructor(props) {
    super(props);
    this.bringToFront = this.bringToFront.bind(this);
  }

  bringToFront() {
    if (this.props.topZIndex >= this.props.z) {
      const newZ = this.props.topZIndex + 1;
      this.props.bringAboutToFront(newZ);
      this.props.incrementTopZ();
    }
  }

  render() {
    return (
      <div className={styles.helpcontainer}>
        <img
          className={styles.help + " ignore-react-onclickoutside"}
          src={icon}
          onClick={this.bringToFront}
        />
      </div>
    );
  }
}

HelpButton.propTypes = {
  bringAboutToFront: PropTypes.func,
  incrementTopZ: PropTypes.func,
  z: PropTypes.number,
  topZIndex: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    topZIndex: state._ui.topZIndex,
    z: state._about.z,
  };
};

const ConnectedHelpButton = connect(mapStateToProps, {
  bringAboutToFront,
  incrementTopZ,
})(HelpButton);

export default ConnectedHelpButton;
