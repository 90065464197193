import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./Background.module.css";

import { changeBackground } from "../../redux/modules/background";

/**
 * @author  Conan Lai
 * @description Background component which receives background property from redux
 * allows for user selectable backgrounds
 */

class Background extends React.PureComponent {
  render() {
    const bgstyle = {};
    if (this.props.selectedBackground) {
      bgstyle.backgroundImage = `url('${
        this.props.backgrounds[this.props.selectedBackground].url
      }')`;
      if (this.props.backgrounds[this.props.selectedBackground].tiled) {
        bgstyle.backgroundRepeat = "repeat";
        bgstyle.backgroundSize = "auto";
      }
    }
    return <div className={styles.background} style={bgstyle} />;
  }
}

Background.propTypes = {
  backgrounds: PropTypes.object,
  selectedBackground: PropTypes.string,
  changeBackground: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    backgrounds: state._background.backgrounds,
    selectedBackground: state._background.selectedBackground,
  };
};

const connectedBackground = connect(mapStateToProps, { changeBackground })(
  Background
);

export default connectedBackground;
