import config from "../../../config";

export default function reducer(
  state = {
    open: "closed",
    z: 1,
    w: 0,
    h: 0,
    x: 0,
    y: 0,
    selectedShow: "",
    data: {},
  },
  action = {}
) {
  switch (action.type) {
    case "CALCULATE_SHOW":
      return {
        ...state,
        x: action.x,
        y: action.y,
        w: action.w,
        h: action.h,
        open: action.open,
        selectedShow: action.selectedShow,
      };
    case "RECALCULATE_SHOW_ON_MOBILE":
      return {
        ...state,
        x: action.x,
        y: action.y,
        w: action.w,
        h: action.h,
      };
    case "OPEN_SHOW":
      return {
        ...state,
        open: "open",
        z: action.z,
      };
    case "CLOSE_SHOW":
      return {
        ...state,
        open: "closed",
      };
    case "REQUEST_SHOW":
      return {
        ...state,
        isFetching: true,
        selectedShow: "",
      };
    case "RECEIVE_SHOW":
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          [action.slug]: action.json,
        },
        selectedShow: action.slug,
      };
    case "BRING_SHOW_TO_FRONT":
      return {
        ...state,
        z: action.z,
        open: "open",
      };
    default:
      return state;
  }
}

export const calculateShow = (params) => {
  const {
    isMobile,
    windowHeight,
    windowWidth,
    landedSlug,
    mobileOrientation,
  } = params;
  if (isMobile) {
    return {
      type: "CALCULATE_SHOW",
      x: 0,
      y: 0,
      w: mobileOrientation === "landscape" ? windowWidth - 150 : windowWidth,
      h: mobileOrientation === "landscape" ? windowHeight : windowHeight - 150,
      open: landedSlug ? "open" : "closed",
      selectedShow: landedSlug || "acte-musique",
    };
  }
  return {
    type: "CALCULATE_SHOW",
    x: 400,
    y: 50,
    w: 350,
    h: 400,
    open: landedSlug ? "open" : "closed",
    selectedShow: landedSlug || "acte-musique",
  };
};

export const recalculateShowOnMobile = (params) => {
  const { windowWidth, windowHeight, mobileOrientation } = params;
  return {
    type: "RECALCULATE_SHOW_ON_MOBILE",
    x: 0,
    y: 0,
    w: mobileOrientation === "landscape" ? windowWidth - 150 : windowWidth,
    h: mobileOrientation === "landscape" ? windowHeight : windowHeight - 150,
  };
};

export const openShow = (z) => {
  return {
    type: "OPEN_SHOW",
    z,
  };
};

export const closeShow = (e) => {
  // stop propagation here to prevent handleBringToFront from getting called
  if (e && e.target) {
    e.stopPropagation();
  }
  return {
    type: "CLOSE_SHOW",
  };
};

export const bringShowToFront = (z) => {
  return {
    type: "BRING_SHOW_TO_FRONT",
    z,
  };
};

export const requestShow = () => {
  return {
    type: "REQUEST_SHOW",
  };
};

export const receiveShow = (slug, json) => {
  return {
    type: "RECEIVE_SHOW",
    slug,
    json,
  };
};

export const receiveShowError = (err) => {
  return {
    type: "RECEIVE_SHOW_ERROR",
    error: err,
  };
};

export const changeShow = (slug) => {
  return (dispatch) => {
    dispatch(requestShow());
    return fetch(`${config.api_path}/shows/${slug}`)
      .then((response) => {
        const status = response.status;
        switch (status) {
          case 200:
            return response.json();
          default:
            return Promise.reject(status);
        }
      })
      .then((json) => {
        dispatch(receiveShow(slug, json));
      })
      .catch((err) => {
        dispatch(receiveShowError(err));
      });
  };
};
