import React from 'react';
import styles from '../AdminEditor.module.css';
import config from '../../../../config';

class InstagramAccess extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			valid: true,
			error: false,
			clientId: '',
		};
	}

	componentDidMount() {
		this.mounted = true;
		fetch(`${config.api_path}/instagram/info`, {
			method: 'post',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				if (response.status === 401) {
					this.props.signOut();
				} else {
					return response.json();
				}
			})
			.then((json) => {
				this.setState({
					clientId: json.instagramClientId,
					valid: json._doc.valid,
				});
			})
			.catch((error) => {
				this.setState({
					error,
				});
			});
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	renderIsValid() {
		switch (this.state.valid) {
			case true:
				return <p style={{ color: 'blue' }}>the access token is valid</p>;
			case false:
				return <p style={{ color: 'red' }}>the access token is NOT valid</p>;
			default:
				return <p style={{ color: 'blue' }}>the access token is valid or there was error in finding it</p>;
		}
	}

	render() {
		const redirect = `${window.location.origin}/admin`;
		return (
			<div>
				<div className={styles.section}>
					{this.renderIsValid()}
					<p>
						we use the instagram API to fetch new images, this requires authenticating with the Instagram
						auth flow.
					</p>
					<p>
						occasionally our access token expires, and this form allows us to renew it and save it in the
						database so our fetcher can work.
					</p>
					<p>
						if the website has not shown the last image for a while and the indicator above denotes an
						invalid token,
					</p>
					<p>
						please ensure you are logged in to the n10.as instagram and click below to get a new access
						token.
					</p>
					<a
						href={`https://api.instagram.com/oauth/authorize/?client_id=${this.state.clientId}&redirect_uri=${redirect}&response_type=code`}
					>
						click here to get an instagram access token
					</a>
				</div>
			</div>
		);
	}
}

export default InstagramAccess;
