import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styles from './InfoPanel.module.css';

import { changeTimeZone } from '../../../redux/modules/ui';

import config from '../../../config';

/**
 * @author  Conan Lai & Francis Goodship
 * @description info panel showing montreal time
 */

const timeZones = {
	montreal: 'America/New_York',
};

class InfoPanel extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			icon: '',
			temperature: 0,
			summary: '',
			city: 'montreal',
			time: '00:00',
			date: ' ',
		};
		this.getTime = this.getTime.bind(this);
	}

	componentDidMount() {
		this.getTime();
		this.timeCounter = setInterval(this.getTime, 2000);
	}

	componentWillUnmount() {
		clearInterval(this.timeCounter);
	}

	getTime() {
		const time = moment.tz(timeZones[this.state.city]).format('HH:mm');
		const date = moment.tz(timeZones[this.state.city]).format('DD/MM');
		this.setState({
			time,
			date,
		});
	}

	render() {
		return (
			<div className={styles.infopanel}>
				<div className={styles.onlive}>
					<p>ON LIVE</p>
				</div>
				<div className={styles.time}>{this.state.time}</div>
				<div>
          {/* empty div for alignment purposes */}
				</div>
			</div>
		);
	}
}

InfoPanel.propTypes = {
	timezone: PropTypes.string,
	changeTimeZone: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		timezone: state._ui.timezone,
	};
};

const ConnectedInfoPanel = connect(mapStateToProps, { changeTimeZone })(InfoPanel);

export default ConnectedInfoPanel;
