import React from 'react';
import styles from './LoginForm.module.css';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { setUserToReduxState, setUser, handleCognitoSignIn } from '../../../redux/modules/user';

import { validationSchema, initialValues } from '../Validations/LoginValidation';
import { Formik } from 'formik';

function LoginForm({ authenticated, coginitoUserLoading, cognitoUserError, handleCognitoSignIn, errorMsg }) {
	return (
		<React.Fragment>
			{authenticated ? <Redirect to="/admin" /> : null}
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					handleCognitoSignIn(values.email, values.password);
				}}
			>
				{({ handleSubmit, handleChange, values, errors }) => {
					return (
						<div className={styles.container}>
							<form name="login-form" method="post" onSubmit={handleSubmit}>
								<div className={styles.labelrow}>
									<label>
										<p className={styles.p}>Email:</p>
										<input
											type="email"
											onChange={handleChange}
											value={values.email}
											name="email"
										/>{' '}
									</label>
								</div>
								<div className={styles.labelrow}>
									<label>
										<p className={styles.p}>Password:</p>
										<input
											type="password"
											onChange={handleChange}
											value={values.password}
											name="password"
										/>{' '}
									</label>
								</div>
								<button className={styles.button} type="submit">
									Login
								</button>
								<p className={styles.warning}>{cognitoUserError ? errorMsg : null}</p>
							</form>
						</div>
					);
				}}
			</Formik>
		</React.Fragment>
	);
}

const mapStateToProps = (state) => {
	return {
		authenticated: state._user.authenticated,
		cognitoUserError: state._user.cognitoUserError,
		coginitoUserLoading: state._user.coginitoUserLoading,
		errorMsg: state._user.errorMsg,
	};
};

const ConnectedLogin = connect(mapStateToProps, {
	setUserToReduxState,
	setUser,
	handleCognitoSignIn,
})(withRouter(LoginForm));

export default ConnectedLogin;
