import React from 'react';
import RichTextEditor from 'react-rte';
import ImageUploader from '../../EditorUI/ImageUploader/ImageUploader';
import styles from '../AdminEditor.module.css';
import config from '../../../../config';

class EditAnnouncement extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			image: '',
			body: RichTextEditor.createEmptyValue(),
			date: new Date(),
			id: '',
		};
		this.handleName = this.handleName.bind(this);
		this.handleBody = this.handleBody.bind(this);
		this.handleNewImage = this.handleNewImage.bind(this);
		this.handleDeleteImage = this.handleDeleteImage.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.updateData = this.updateData.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	componentDidMount() {
		this.mounted = true;
		if (this.props.editMode === 'edit') {
			this.updateData();
		}
	}

	// we use this pattern to refetch data if user selects a different one to edit
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedAnnouncementId !== this.props.selectedAnnouncementId) {
			this.updateData();
		}
		if (prevProps.editMode === 'edit' && this.props.editMode === 'add') {
			this.setState({
				name: '',
				image: '',
				body: RichTextEditor.createEmptyValue(),
				date: new Date(),
				id: '',
			});
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	handleName(event) {
		this.setState({
			name: event.target.value,
		});
	}

	handleBody(value) {
		this.setState({
			body: value,
		});
	}

	handleNewImage(url) {
		this.setState({ image: url });
	}

	handleDeleteImage() {
		this.setState({ image: '' });
	}

	handleDelete() {
		fetch(`${config.api_path}/announcements/${this.state.id}`, {
			method: 'delete',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				if (response.status === 401) {
					this.props.signOut();
				} else {
					return response.json();
				}
			})
			.then((json) => {
				console.log(json);
				this.props.handleRefresh();
			})
			.catch((error) => {
				console.log('Request failed', error);
			});
	}

	handleSubmit() {
		const editMode = this.props.editMode;
		const url =
			editMode === 'edit'
				? `${config.api_path}/announcements/${this.state.id}`
				: `${config.api_path}/announcements`;
		const fetchMode = editMode === 'edit' ? 'put' : 'post';
		const payload = {
			name: this.state.name,
			image: this.state.image,
			body: this.state.body.toString('html'),
			date: this.state.date,
		};

		const payloadJSON = JSON.stringify(payload);
		fetch(url, {
			method: fetchMode,
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
			body: payloadJSON,
		})
			.then((response) => {
				if (response.status === 401) {
					this.props.signOut();
				} else {
					return response.json();
				}
			})
			.then((json) => {
				console.log(json);
				this.props.handleRefresh();
			})
			.catch((error) => {
				console.log('Request failed', error);
			});
	}

	updateData() {
		fetch(`${config.api_path}/announcements/${this.props.selectedAnnouncementId}`)
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				// this line is here because the server returns an array even if there is only
				// one show with given slug
				const announcement = json;
				if (this.mounted === true) {
					this.setState({
						name: announcement.name,
						image: announcement.image,
						body: RichTextEditor.createValueFromString(announcement.body, 'html'),
						date: announcement.date,
						id: announcement._id,
					});
				}
			});
	}

	render() {
		const deletebutton =
			this.props.editMode === 'edit' ? (
				<div>
					<button className={styles.button} onClick={this.handleDelete}>
						delete this
					</button>
				</div>
			) : null;
		const deleteimagebutton =
			this.state.image !== '' ? (
				<div>
					<button onClick={this.handleDeleteImage}>delete image</button>
				</div>
			) : null;
		return (
			<div>
				<div className={styles.section}>
					<span className={styles.label}>announcement title: </span>
					<input value={this.state.name} onChange={this.handleName} className={styles.input} />
				</div>
				<div className={styles.section}>
					<span className={styles.label}>body: </span>
					<RichTextEditor value={this.state.body} onChange={this.handleBody} />
				</div>
				<div className={styles.section}>
					<span className={styles.label}>date: </span>
					{this.state.date.toString()}
				</div>
				<div className={styles.section}>
					<span className={styles.label}>image preview: </span>
					{this.state.image}
					<br />
					<img className={styles.image} alt={this.state.name} src={this.state.image} />
					<br />
					{deleteimagebutton}
					upload new image: <ImageUploader preset="" handleNewImage={this.handleNewImage} />
				</div>
				<div className={styles.section}>
					{deletebutton}
					<div>
						<button className={styles.button} onClick={this.handleSubmit}>
							submit this
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default EditAnnouncement;
