import React from 'react';
import ImageUploader from '../../EditorUI/ImageUploader/ImageUploader';
import styles from '../AdminEditor.module.css';
import config from '../../../../config';

const initialState = {
	name: '',
	image: '',
	button: '',
	price: 0,
	id: '',
	available: true,
};

class EditShop extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.handleName = this.handleName.bind(this);
		this.handlePrice = this.handlePrice.bind(this);
		this.handleButton = this.handleButton.bind(this);
		this.handleNewImage = this.handleNewImage.bind(this);
		this.handleAvailable = this.handleAvailable.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	componentDidMount() {
		this.mounted = true;
		if (this.props.editMode === 'edit') {
			this.updateData();
		}
	}

	// we use this pattern to refetch data if user selects a different one to edit
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedShopId !== this.props.selectedShopId) {
			this.updateData();
		}
		if (prevProps.editMode === 'edit' && this.props.editMode === 'add') {
			this.setState(initialState);
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	handleName(event) {
		this.setState({
			name: event.target.value,
		});
	}

	handleNewImage(url) {
		this.setState({ image: url });
	}

	handleDeleteImage() {
		this.setState({ image: '' });
	}

	handlePrice(event) {
		this.setState({
			price: event.target.value,
		});
	}

	handleAvailable(event) {
		this.setState({
			available: !this.state.available,
		});
	}

	handleButton(event) {
		this.setState({
			button: event.target.value,
		});
	}

	handleDelete() {
		fetch(`${config.api_path}/shop/${this.state.id}`, {
			method: 'delete',
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				if (response.status === 401) {
					this.props.signOut();
				} else {
					return response.json();
				}
			})
			.then((json) => {
				this.props.handleRefresh();
			})
			.catch((error) => {
				console.log('Request failed', error);
			});
	}

	handleSubmit() {
		const editMode = this.props.editMode;
		const url = editMode === 'edit' ? `${config.api_path}/shop/${this.state.id}` : `${config.api_path}/shop`;
		const fetchMode = editMode === 'edit' ? 'put' : 'post';
		const payload = {
			name: this.state.name,
			image: this.state.image,
			detail: this.state.detail,
			button: this.state.button,
			price: this.state.price,
			available: this.state.available,
		};

		const payloadJSON = JSON.stringify(payload);
		fetch(url, {
			method: fetchMode,
			headers: {
				jwt: localStorage.getItem('user_token'),
				'Content-Type': 'application/json',
			},
			body: payloadJSON,
		})
			.then((response) => {
				if (response.status === 401) {
					this.props.signOut();
				} else {
					return response.json();
				}
			})
			.then((json) => {
				console.log(json);
				this.props.handleRefresh();
			})
			.catch((error) => {
				console.log('Request failed', error);
			});
	}

	updateData() {
		fetch(`${config.api_path}/shop/${this.props.selectedShopId}`)
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				// this line is here because the server returns an array even if there is only
				// one show with given slug
				const shop = json;

				console.log(shop);
				if (this.mounted === true) {
					this.setState({
						id: this.props.selectedShopId,
						name: shop.name,
						image: shop.image,
						detail: shop.detail,
						price: shop.price,
						button: shop.button,
						available: shop.available,
					});
				}
			});
	}

	render() {
		const deletebutton =
			this.props.editMode === 'edit' ? (
				<div>
					<button className={styles.button} onClick={this.handleDelete}>
						delete this
					</button>
				</div>
			) : null;
		return (
			<div>
				<div className={styles.section}>
					<span className={styles.label}>name of product: </span>
					<input value={this.state.name} onChange={this.handleName} className={styles.input} />
				</div>
				<div className={styles.section}>
					<br />
					<input
						name="available"
						type="checkbox"
						checked={this.state.available}
						onChange={this.handleAvailable}
					/>
					Check this box if item is in stock and available to be purchased
				</div>
				<div className={styles.section}>
					<span className={styles.label}>button code from paypal: </span>
					<textarea
						rows="10"
						value={this.state.button}
						onChange={this.handleButton}
						className={styles.input}
					/>
				</div>
				<div className={styles.section}>
					<span className={styles.label}>price (enter just a number): </span>
					<input
						className={styles.input}
						type="number"
						value={this.state.price}
						onChange={this.handlePrice}
						min="1"
						max="100"
						placeholder="30"
					/>
				</div>
				<div className={styles.section}>
					<span className={styles.label}>main image preview: </span>
					{this.state.image}
					<br />
					<img className={styles.image} alt={this.state.name} src={this.state.image} />
					upload new main image:{' '}
					<ImageUploader preset="n10as_shopandshow" handleNewImage={this.handleNewImage} />
				</div>
				<div className={styles.section}>
					{deletebutton}
					<div>
						<button className={styles.button} onClick={this.handleSubmit}>
							submit this
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default EditShop;
