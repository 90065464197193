import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import RangePicker from "../RangePicker/RangePicker";
import styles from "./OpacitySlider.module.css";

import { changeOpacity } from "../../../redux/modules/ui";

/**
 * @author  Conan Lai
 * @description allows user to change opacity of backgrounds on content windows
 */

class OpacitySlider extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    if (!this.props.isMobile) {
      this.props.toggleOpacityOpen();
    }
  }

  render() {
    return (
      <div className={styles.opacity}>
        <RangePicker
          vertical
          value={this.props.opacity}
          handleChange={this.props.changeOpacity}
        />
      </div>
    );
  }
}

OpacitySlider.propTypes = {
  toggleOpacityOpen: PropTypes.func,
  changeOpacity: PropTypes.func,
  isMobile: PropTypes.bool,
  opacity: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    opacity: state._ui.opacity,
  };
};

const ConnectedOpacitySlider = connect(mapStateToProps, { changeOpacity })(
  onClickOutside(OpacitySlider)
);

export default ConnectedOpacitySlider;
