export default function reducer(state = {
  open: 'closed',
  z: 1,
  w: 0,
  h: 0,
  x: 0,
  y: 0,
}, action = {}) {
  switch (action.type) {
    case 'CALCULATE_ABOUT':
      return {
        ...state,
        x: action.x,
        y: action.y,
        w: action.w,
        h: action.h,
        open: action.open,
      };
    case 'RECALCULATE_ABOUT_ON_MOBILE':
      return {
        ...state,
        x: action.x,
        y: action.y,
        w: action.w,
        h: action.h,
      };
    case 'OPEN_ABOUT':
      return {
        ...state,
        open: 'open',
        z: action.z + 1,
      };
    case 'CLOSE_ABOUT':
      return {
        ...state,
        open: 'closed',
      };
    case 'BRING_ABOUT_TO_FRONT':
      return {
        ...state,
        z: action.z,
        open: 'open',
      };
    default:
      return state;
  }
}

export const calculateAbout = (params) => {
  const { isMobile, windowHeight, windowWidth, landedSlug, mobileOrientation, } = params;
  if (isMobile) {
    return {
      type: 'CALCULATE_ABOUT',
      x: 0,
      y: 0,
      w: mobileOrientation === 'landscape' ? windowWidth - 150 : windowWidth,
      h: mobileOrientation === 'landscape' ? windowHeight : windowHeight - 150,
      open: 'closed',
    };
  }
  const width = Math.max(windowWidth / 3, 300);
  const height = Math.max(width * 0.75, 300);
  return {
    type: 'CALCULATE_ABOUT',
    x: 40,
    y: 400,
    w: width,
    h: height,
    open: 'open',
  };
};

export const recalculateAboutOnMobile = (params) => {
  const { windowWidth, windowHeight, mobileOrientation, } = params;
  return {
    type: 'RECALCULATE_ABOUT_ON_MOBILE',
    x: 0,
    y: 0,
    w: mobileOrientation === 'landscape' ? windowWidth - 150 : windowWidth,
    h: mobileOrientation === 'landscape' ? windowHeight : windowHeight - 150,
  };
};

export const openAbout = (z) => {
  return {
    type: 'OPEN_ABOUT',
    z,
  };
};

export const closeAbout = (e) => {
  // stop propagation here to prevent handleBringToFront from getting called
  if (e && e.target) {
    e.stopPropagation();
  }
  return {
    type: 'CLOSE_ABOUT',
  };
};

export const bringAboutToFront = (z) => {
  return {
    type: 'BRING_ABOUT_TO_FRONT',
    z,
  };
};
