import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ArchivePlayer from "../ArchivePlayer/ArchivePlayer";
import Marquee from "../../../lib/Marquee";
import styles from "./NowPlaying.module.css";

import { changeShow } from "../../../../redux/modules/content/show";

/**
 * @author  Conan Lai
 * @description displays name of current broadcast or current selected archive show
 */

class NowPlaying extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      slug: "",
    };
    this.findSlug = this.findSlug.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // if user is on live mode, iterate over list of all show names to find match
    // with current show, if exists, set slug
    if (
      prevProps.current !== this.props.current ||
      this.props.showslist.length !== prevProps.showslist.length
    ) {
      this.findSlug();
    }
  }

  findSlug() {
    let slug;
    for (let i = 0; i < this.props.showslist.length; i += 1) {
      if (
        this.props.current
          .toLowerCase()
          .includes(this.props.showslist[i].name.toLowerCase())
      ) {
        slug = this.props.showslist[i].slug;
        break;
      }
    }
    this.setState({
      slug,
    });
  }

  handleClick() {
    if (
      this.state.slug &&
      (this.props.source === "live" ||
        this.props.source === "pausedlive" ||
        this.props.source === "none")
    ) {
      this.props.changeShow(this.state.slug);
    }
  }

  render() {
    let showname = "";
    if (
      this.props.source === "live" ||
      this.props.source === "pausedlive" ||
      this.props.source === "none"
    ) {
      showname = this.props.current;
    } else if (
      this.props.source === "archive" ||
      this.props.source === "pausedarchive"
    ) {
      showname = this.props.selectedArchiveName;
    }

    // fix for mobile safari via nowplaying prop
    const nowplaying =
      this.props.isMobile &&
      (this.props.source === "archive" ||
        this.props.source === "pausedarchive") ? null : (
        <Marquee nowplaying={!this.props.isMobile}>
          <span dangerouslySetInnerHTML={{ __html: showname }} />
        </Marquee>
      );

    return (
      <div className={styles.holder}>
        <div
          className={styles.nowplaying}
          onClick={this.handleClick}
          style={{ cursor: this.state.slug ? "crosshair" : "auto" }}
        >
          <ArchivePlayer />
          {nowplaying}
        </div>
      </div>
    );
  }
}

NowPlaying.propTypes = {
  current: PropTypes.string,
  source: PropTypes.string,
  selectedArchiveName: PropTypes.string,
  showslist: PropTypes.array,
  changeShow: PropTypes.func,
  isMobile: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    showslist: state._shows.shows,
    source: state._audio.source,
    current: state._airtime.current,
    selectedArchiveName: state._archives.selectedArchiveName,
    isMobile: state._ui.isMobile,
  };
};

const ConnectedNowPlaying = connect(mapStateToProps, { changeShow })(
  NowPlaying
);

export default ConnectedNowPlaying;
